import { BehaviorSubject } from "rxjs";
import { User } from "../../../../shared/models/user";
import { Docs, Type } from "../../../../shared/components";
import { DocModel } from "../state/state";

export const JOBTITLE_OM: any = "om";
export const JOBTITLE_KM: any = "km";
export const JOBTITLE_USER: any = "user";

export interface UsersServiceType {
  /** users subject is emited as soon as the request for the users is done */
  usersSubject: BehaviorSubject<boolean>;

  /** bussiness area that the user belongs to */
  businessAreaUser: string;

  /** function that gest the list of users compressed */
  getListOfUsersCompressed(): Promise<void>;

  /** get the list of all users that have the displayAsComponent property set to true */
  getComponentResponsibles(): Promise<void>;

  /** list of all users that have the displayAsComponent property set to true  - used in thread.componentResponsibles*/
  componentResponsibles: string[];

  /** get the list of the users along with their roles */
  getUsersWithRoles(): Promise<void>;

  /** list of users and their roles - to be used in the dropdowns as options*/
  /** retrieves only the active & users that don't have the analyst role */
  userRoles: string[];

  /** function that gets the list of users */
  getListOfUsers(): Promise<void>;

  /**the compressed list of users - contains only the user and the roles */
  usersCompressed: Docs[Type][];

  /** current loaded users */
  users: Docs[Type][];

  /** the current loaded users mapped into an array of ids */
  userIds: string[];

  /** the user that has been chosen from the list view */
  userName: string;

  /** function that returns the user by the userName - databaseId = userName */
  getUserById(userId: string): Promise<User>;

  /**the selected user's id */
  currentId: string;

  /** current selected user */
  currentUser: User;

  /** get list of required fields */
  requiredFields: string[];

  /** method to check password policy before saving */
  checkPassword(model: DocModel): any;

  /** get the list of the current box and add/delete fields based on the customer */
  getListOfFields(box: string): string[];

  /** save/update for a user */
  save(): Promise<void>;

  /** delete a user */
  delete(): Promise<void>;

  /** set the property active to false */
  deactivateUser(): Promise<void>;

  /** The user model (an object used for editing) */
  model: DocModel;

  /** Create a user model based on the document with the user id */
  getUserModelClean(): DocModel;

  /** Does the user model have changes?  */
  readonly modelHasChanges: boolean;

  /** Does the user model from the state have changes?  */
  readonly stateModelHasChanges: boolean;

  /** Is the currently selected user a new document which has not been saved yet? */
  readonly isNew: boolean;

  /** Does the currently logegd in user have the right to edit a user document with the given id */
  hasEditPermission(id: string): boolean;

  /** Get the list of users with specific role */
  getOptions(role?: string, jobTitle?: string): string[];

  /**Set password so that it never expires */
  setPasswordNeverExpire(): void;

  /**clear user model so in the filter input will be empty when selecting a filter */
  resetUserModel(): void;

  prepareUser(): void;

  setLanguage(language: string): Promise<void>;

  /**Get the roles of a user which is sent as a param */
  getUserRoles(user: string): string[];

  isDisabled(): boolean;

  /** Export users as csv */
  exportData(): Promise<void>;
}
