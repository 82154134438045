<div class="row">
  <!-- <div
    id="collapseFilter"
    class="col-sm-3 col-md-2 col-xl-2 p-0"
    *ngIf="app.filterList.open"
  >
    <app-filter></app-filter>
  </div> -->
  <div class="col-sm-12 col-md-12 col-xl-12">
    <div class="d-flex justify-content-end no-gutters pt-3">
      <button
        class="btn btn-sm btn-default-red"
        data-toggle="collapse"
        data-target="#collapseFilter"
        [disabled]="!app.profile.profiles.length"
        (click)="app.filterList.open = !app.filterList.open"
        [ngClass]="{ btnFilterOpen: app.filterList.open }"
      >
        <i class="fas fa-filter"></i>
        {{ app.text.home.filters }}
        <i *ngIf="!app.filterList.open" class="fal fa-angle-right"></i>
        <i *ngIf="app.filterList.open" class="far fa-times"></i>
      </button>
      <!-- <button
          class="btn pull-right"
          *ngIf="!app.users.isTeam"
          (click)="app.routing.navigateNewUser()"
          [ngbTooltip]="app.text.user.add"
        >
          <i class="fa fa-plus"></i>
        </button> -->
    </div>
    <div class="row pt-3">
      <div class="col-md-12 pull-right">
        <app-filter-fields></app-filter-fields>
      </div>
    </div>
    <app-paginator
      [length]="app.profile.profiles.length"
      [index]="app.paginator.focus"
      (focus)="app.paginator.focus = $event"
    ></app-paginator>
    <div>
      <div class="tableFixHead">
        <table class="table table-striped spacing-table">
          <thead>
            <tr class="pointer">
              <th class="p-2">No.</th>
              <th
                class="p-2"
                *ngFor="let column of app.list.personProfile.columns"
              >
                <span>
                  {{ app.field.getLabel(column) }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let profile of app.paginator.getPageWithDoc();
                let i = index
              "
              [ngClass]="{ isSelected: app.isSelected(profile) }"
              (click)="app.routing.navigateProfile(profile._id)"
              class="pointer"
            >
              <td class="px-2 py-1">{{ i + 1 }}</td>
              <td
                class="px-2 py-1"
                *ngFor="let column of app.list.personProfile.columns"
              >
                <span>
                  {{ getData(profile, column) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <app-paginator
      [length]="app.profile.profiles.length"
      [index]="app.paginator.focus"
      (focus)="app.paginator.focus = $event"
    ></app-paginator>
  </div>
</div>
