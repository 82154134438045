import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { getApp } from "../../app";
@Component({
  selector: "app-oc-post-item",
  templateUrl: "./OC-post-item.component.html",
  styleUrls: ["./OC-post-item.component.scss"],
})
export class OCPostItemComponent implements OnInit, OnDestroy {
  @Input() post = {} as any;

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {}

  ngOnDestroy() {}
}
