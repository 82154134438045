import { UsersServiceType } from "./users.service.type";
import { AppType, APP_TYPE } from "../app.type";
import { loadModel, hasChanges } from "../utils/app.utils";
import { DocModel } from "../state/state";
import { Docs, Type } from "../../../../shared/components";
import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
  deleteUser,
  doc2Model,
  getComponentResponsibles,
  getCurrentUser,
  getUsersCompressed,
  getUsersList,
  getUsersWithRoles,
  model2Doc,
  saveUser,
  setUserLanguage,
} from "../api.service";
import { User } from "../../../../shared/models/user";
import { checkPasswordPolicy } from "../password/password-policy";
import { Customer } from "../../../../shared/types/customers";
import { environment } from "../../environments/environment";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { writeConfigFile } from "../export/config-export";

@Injectable()
export class UsersService implements UsersServiceType {
  usersSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  usersCompressed: Docs[Type][] = [];

  users: Docs[Type][] = [];

  userIds: string[] = [];

  currentUser = new User();

  userName: string = "";

  model: DocModel = {};
  currentId: string = "";
  businessAreaUser: string = "";

  cleanUsers: Docs[Type][] = [];
  componentResponsibles: string[] = [];
  userRoles: [] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getListOfUsers() {
    this.users = await getUsersList(this.app.customers.expectCurrent);
    this.userIds = this.users.map((user) => user._id);
    this.cleanUsers = this.users;
  }

  getUserModelClean(user = this.currentUser) {
    let model: DocModel = {};
    model = doc2Model("user", this.currentUser);

    loadModel("profile", this.app.profile.selectedProfile, model);
    if (this.app.profile.selectedProfile.sections) {
      loadModel(
        "personProfile",
        this.app.profile.selectedProfile.sections.pers,
        model
      );
      loadModel(
        "organizationProfile",
        this.app.profile.selectedProfile.sections.org,
        model
      );
    }
    this.model = model;
    return model;
  }

  //clear user model so in the filter input will be empty when selecting a filter
  resetUserModel() {
    this.model[this.app.fieldId.user.name] = "";
    this.model[this.app.fieldId.user.email] = "";
    this.model[this.app.fieldId.user.roles] = "";
    this.model[this.app.fieldId.user.active] = "";
  }

  get isNew() {
    if (this.currentUser.name != null && this.currentUser.name !== "") {
      return false;
    }
    let isNew: boolean = false;
    let index = this.app.users.users.findIndex(
      (user) => user._id === this.currentUser._id
    );
    if (index !== -1) {
      isNew = false;
    } else {
      isNew = true;
    }
    if (isNew && this.model["user.passwordNeverExpire"] === undefined) {
      this.model["user.passwordNeverExpire"] = false;
    }
    return isNew;
  }

  get modelHasChanges() {
    if (Object.keys(this.app.state.userModel).length === 0) {
      return false;
    }
    const modelHasChanges = hasChanges(this.model, this.app.state.userModel);
    if (modelHasChanges && this.model.password !== "") {
      this.setPasswordNeverExpire();
    }
    return modelHasChanges;
  }

  get stateModelHasChanges() {
    const modelHasChanges = hasChanges(this.app.state.model, this.model);
    return modelHasChanges;
  }

  isDisabled() {
    if (this.app.users.requiredFields.length === 0 && this.modelHasChanges) {
      return false;
    } else {
      return true;
    }
  }

  hasEditPermission(id: string) {
    const { permission } = this.app;
    // let user = this.app.user;
    if (permission.user.manage) {
      return true;
    }
    // if (permission.user.editOwn) {
    //   if (id === user) {
    //     return true;
    //   }
    // }
    return false;
  }
  getOptions(role?: string, jobTitle?: string) {
    let currentRole = this.app.customers.expectCurrent + "-" + role;
    let isProductionMode = environment.production;
    let users: any[] = [];
    if (this.userRoles.length > 0) {
      if (currentRole !== undefined && jobTitle == undefined) {
        users = this.userRoles.filter((user: any) =>
          user.roles.includes(currentRole)
        );
      } else {
        users = this.userRoles.filter(
          (user: any) =>
            user.jobTitle != null && user.jobTitle.includes(jobTitle)
        );
      }
    }

    let result: string[] = [];
    if (isProductionMode) {
      /** https://app.asana.com/0/1203066793253695/1199525579744726  */
      /** do not show amsys users in dropdowns in production mode */
      result = users
        .filter((user: any) => !user.name.toLowerCase().includes("amsys"))
        .map((result) => result.name);
      // !user.email.includes("@am-sys.com")
    } else {
      result = users.map((result) => result.name);
    }
    return result as any;
  }

  setPasswordNeverExpire() {
    // date after 6 months starting from today
    const d = new Date();
    d.setMonth(d.getMonth() + 6);
    const nextExpireDate = d.toISOString().slice(0, 10);
    if (this.app.users.currentUser == null) {
      return;
    }
    const expiryDate = this.model["user.passwordExpireDate"];
    if (!this.model["user.passwordNeverExpire"]) {
      if (
        (!this.isNew &&
          !StringUtils.isNullOrEmpty(this.model["user.password"])) ||
        StringUtils.isNullOrEmpty(expiryDate)
      ) {
        this.model["user.passwordExpireDate"] = nextExpireDate;
      }
    }
  }

  async getListOfUsersCompressed() {
    this.usersCompressed = await getUsersCompressed(
      this.app.customers.expectCurrent
    );
    const currentUser = this.usersCompressed.find(
      (user: any) => user.name === this.app.user
    ) as User;
    this.businessAreaUser = (currentUser as any).businessArea;
    this.usersSubject.next(true);
  }

  async getComponentResponsibles() {
    if (this.app.customers.expectCurrent !== Customer.DB) {
      return;
    }
    this.componentResponsibles = await getComponentResponsibles(
      this.app.customers.expectCurrent
    );
  }
  async getUsersWithRoles() {
    this.userRoles = await getUsersWithRoles(this.app.customers.expectCurrent);
  }

  async getUserById(userId: string) {
    if (userId != null) {
      this.currentUser = await getCurrentUser(
        this.app.customers.expectCurrent,
        userId
      );
      this.currentUser.retypePassword = "";
      let index = this.currentUser.roles.findIndex((role) =>
        role.includes("admin")
      );
      if (index !== -1) {
        this.currentUser.roles = [this.currentUser.roles[index]];
      } else {
        this.currentUser.roles = [this.currentUser.roles[0]];
      }
    } else {
      this.currentUser = new User();
      this.currentUser.roles = [];
      this.currentUser.active = true;
    }
    return this.currentUser;
  }

  get requiredFields() {
    const fieldId = this.app.fieldId.user.name;
    this.app.field.resetAllRequiredFieldSettings("user");
    if (!this.app.users.isNew) {
      this.app.field.getFieldSettings(fieldId).mutable = false;
      this.app.field.getFieldSettings("user.password").required = false;
      this.app.field.getFieldSettings("user.retypePassword").required = false;
      this.app.field.getFieldSettings("user.email").mutable = false;
      this.app.field.getFieldSettings("user.roles").required = true;
    } else {
      this.app.field.getFieldSettings(fieldId).mutable = true;
      this.app.field.getFieldSettings("user.name").required = true;
      this.app.field.getFieldSettings("user.email").required = true;
      this.app.field.getFieldSettings("user.roles").required = true;
      this.app.field.getFieldSettings("user.password").required = true;
      this.app.field.getFieldSettings("user.retypePassword").required = true;
    }
    let requiredFields = this.app.field.getInvalidFields("user", this.model);
    return requiredFields;
  }

  checkPassword(model: DocModel) {
    const result = checkPasswordPolicy(this.app.text, model, "user");

    if (result !== "No error") {
      this.app.hasError = true;
      this.app.errorText = result;
    }

    return result;
  }

  async delete() {
    const customer = this.app.customers.expectCurrent;
    let doc: User = this.app.users.currentUser;
    await deleteUser(customer, doc.name);

    if (this.app.users.currentUser.name === this.app.user) {
      this.app.auth.logout();
    }
    this.app.routing.navigateUsers();
  }

  getListOfFields(box: string) {
    const list = new Set(this.app.getList(box));
    if (this.model["user.passwordNeverExpire"] === true) {
      list.delete("user.passwordExpireDate");
      this.model[this.app.fieldId.user.passwordExpireDate] = "";
      this.app.field.getFieldSettings("user.passwordExpireDate").required =
        false;
    } else {
      list.add("user.passwordExpireDate");
      this.app.field.getFieldSettings("user.passwordExpireDate").required =
        true;
    }
    if (this.app.customer === Customer.DB) {
      if (
        this.model["user.roles"] !== undefined &&
        this.model["user.roles"].includes(
          `${this.app.customers.expectCurrent}-user`
        )
      ) {
        list.add("user.displayAsComponent");
      } else {
        list.delete("user.displayAsComponent");
      }
    }
    return Array.from(list);
  }

  async save() {
    this.app.hasError = false;
    const userDoc = model2Doc("user", this.app.users.model);
    let passwordPolicy: string;
    let userNamePloicy: string;

    userNamePloicy = this.checkUsername(userDoc.name);

    const checkUserExists = this.app.users.users.findIndex(
      (user: any) => user.name === userDoc.name
    );
    if (
      this.app.unlockedId !== null &&
      this.app.users.requiredFields.length == 0 &&
      StringUtils.isNullOrEmpty(userDoc["password"])
    ) {
      passwordPolicy = "No error";
    } else {
      passwordPolicy = this.app.users.checkPassword(this.app.users.model);
    }
    if (
      this.app.users.isNew &&
      (userDoc.password == null || userDoc.password == "")
    ) {
      delete userDoc.password;
    }
    userDoc.name = userDoc.name.trim();
    let user = new User();
    Object.keys(userDoc).forEach((key: string) => {
      user[key] = (userDoc as any)[key];
    });
    if (user.passwordNeverExpire) {
      user.passwordExpireDate = "";
    }
    user.type = "user";
    user._id = `${"org.couchdb.user:" + user.name}`;

    // save user
    if (passwordPolicy === "No error" && userNamePloicy === "No error") {
      if (checkUserExists !== -1 && this.app.users.isNew) {
        this.app.hasError = true;
        this.app.errorText = this.app.text.user.userAlreadyExists;
        this.app.hasSuccess = false;
      } else {
        let result = await saveUser(this.app.customers.expectCurrent, user);

        if (!result.error) {
          if (this.app.users.isNew) {
            this.app.hasSuccess = true;
            this.app.successText = this.app.text.user.successUserCreated;
            this.app.routing.navigateUser(user.name);
          } else {
            this.app.hasSuccess = true;
            this.app.successText = this.app.text.user.succesUserUpdated;
          }
          this.app.unlockedId = null;
          // this.app.routing.navigateUsers();
          this.app.users.prepareUser();
          setTimeout(() => {
            this.app.hasSuccess = false;
          }, 3000);
        } else {
          this.app.hasSuccess = false;
          this.app.hasError = true;
          this.app.errorText = this.app.text.user.passwordUsed;
        }
      }
    }
    this.app.userSettings.updateSettings();
    this.app.field.inputSubject.next(true);
  }
  async prepareUser() {
    await this.app.users.getUserById(this.app.users.userName);
    this.app.users.getUserModelClean();
    let userClean = doc2Model("user", this.app.users.currentUser);
    this.app.state.next({ userModel: userClean });
  }

  async deactivateUser() {
    this.app.unlockedId = this.app.users.currentUser.name;
    this.model[this.app.fieldId.user.active] = false;
    await this.save();
  }

  /**
   * Store the user's preferred language in the user document
   * @param language The language to be stored
   */
  async setLanguage(language: string): Promise<void> {
    if (this.app.user != null) {
      await setUserLanguage(this.app.user, language);
    }
    this.app.header.getHeaderTitle(this.app.state.inboxType);
  }

  /**Get roles of a user */
  getUserRoles(userName: string) {
    let roles: string[] = [];
    let user: any = this.userRoles.find((user: any) => user.name === userName);
    user.roles?.forEach((role: string) => {
      roles.push(role.split("-")[1]);
    });
    return roles;
  }

  private checkUsername(username: string) {
    const format: RegExp = /[!^()\=\[\]{};':"\\|<>\/?@#$%*]+/;
    if (StringUtils.containsSpecialCharacter(username, format)) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.user.usernameNotAccepted;
      return this.app.text.user.usernameNotAccepted;
    } else {
      return "No error";
    }
  }

  async exportData() {
    await writeConfigFile(this.app, "user");
  }
}
