import jwt_decode from "jwt-decode";
import { APP_TYPE, AppType } from "../app.type";
import { AuthServiceType, ResetPasswordObject } from "./auth.service.type";
import {
  checkAuth,
  login,
  resetPassword,
  saveNewPassword,
  verifyPassword,
} from "../api.service";
import { DocModel } from "../state/state";
import { Inject, Injectable } from "@angular/core";
import { TOKEN } from "./token";
import { NAVIGATION_HISTORY, REDIRECT_TO } from "../routing/routing.service";
import { Customer } from "../../../../shared/types/customers";
import { PORTAL } from "../../../../shared/constants/portal";
import { LoginDetails } from "../../../../shared/models/login-details";
import { ResetDetails } from "../../../../shared/models/reset-details";
import { CustomerName } from "../../../../shared/config/customers";

const PASSWORD_PLACEHOLDER = "...";

function getDefaultCustomer(customerNames: CustomerName[]): CustomerName {
  let defaultCustomer: CustomerName | null = PORTAL;

  if (customerNames.length > 0) {
    defaultCustomer = customerNames[0];
  }

  return defaultCustomer;
}

@Injectable()
export class AuthService implements AuthServiceType {
  rolesArray: string[] = [];
  hasMTUPermission: boolean = false;
  isTeam: boolean = false;
  isAdmin: boolean = false;
  isUser: boolean = false;
  isRMAdmin: boolean = false;
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async loginToHomePage(details: LoginDetails) {
    this.app.run(async () => {
      await this.login(details);
    });
  }

  async prefillLoginForm(model: DocModel) {
    if (typeof Storage === "undefined") {
      return;
    }

    const token = localStorage.getItem(TOKEN);
    if (token == null || token === "") {
      return;
    }

    const { name } = jwt_decode<{ name: string }>(token);
    const { user } = this.app.getModelProxy(model);
    user.name = name;
    user.password = PASSWORD_PLACEHOLDER;
    user.remember = true;
  }

  async login(details: LoginDetails) {
    const {
      authenticated,
      customerNames,
      token,
      username,
      passwordChangeRequired,
      roles,
    } = await login(details);
    if (authenticated) {
      if (typeof Storage !== "undefined") {
        localStorage.setItem(TOKEN, btoa(token));
      }

      const defaultCustomer = getDefaultCustomer(customerNames);
      if (defaultCustomer != null) {
        this.app.state.next({
          user: username,
          customers: customerNames,
          customer: defaultCustomer,
          isAnalyst: (roles as any)[defaultCustomer].analyst,
          isRMAdmin: (roles as any)[defaultCustomer].RMAdmin,
          passwordChangeRequired,
        });
      }

      if (passwordChangeRequired) {
        await this.app.routing.navigateProfilePassword();
        return;
      }

      if (typeof Storage !== "undefined") {
        const segments = sessionStorage.getItem(REDIRECT_TO);
        if (segments !== null) {
          await this.app.routing.navigate(...JSON.parse(segments));
          return;
        }
      }

      if (defaultCustomer !== null) {
        if (
          (roles as any)[defaultCustomer].analyst ||
          (roles as any)[defaultCustomer].RMAdmin
        ) {
          await this.app.routing.navigateRM();
        } else {
          await this.app.routing.navigateCustomer(
            defaultCustomer as CustomerName
          );
        }
      }

      return;
    } else {
      throw new Error("not logged in");
    }
  }

  async checkAuth(): Promise<boolean> {
    const {
      authenticated,
      customerNames,
      token,
      username,
      passwordChangeRequired,
      roles,
      rolesArray,
    } = await checkAuth();

    if (authenticated) {
      if (typeof Storage !== "undefined") {
        localStorage.setItem(TOKEN, btoa(token));
      }

      const defaultCustomer = getDefaultCustomer(customerNames);
      if (defaultCustomer != null) {
        this.app.state.next({
          user: username,
          customers: customerNames,
          customer: defaultCustomer,
          isAnalyst: (roles as any)[defaultCustomer].analyst,
          isRMAdmin: (roles as any)[defaultCustomer].RMAdmin,
          passwordChangeRequired,
        });
        this.rolesArray = rolesArray;
        if (
          this.rolesArray.findIndex((role: string) =>
            role.includes(Customer.MTU)
          ) !== -1
        ) {
          /** used for displaying a warning when trying to upload an attachment to posts */
          this.hasMTUPermission = true;
        }
      }
    } else {
      this.app.state.next({ user: null });
    }

    return authenticated;
  }

  async resetPassword(details: ResetDetails) {
    await resetPassword(details);
  }

  async savePassword(details: ResetPasswordObject) {
    return await saveNewPassword(details);
  }

  async testPassword(user: string, password: string) {
    return await verifyPassword({ user, password });
    // const { user } = this.app;
    // if (user == null) {
    //   return false;
    // }
    // try {
    // } catch (err) {
    //   return false;
    // }
    // return true;
  }

  async logout() {
    if (typeof Storage !== "undefined") {
      if (localStorage.getItem(TOKEN) !== null) {
        localStorage.removeItem(TOKEN);
      }

      if (sessionStorage.getItem(REDIRECT_TO) !== null) {
        sessionStorage.removeItem(REDIRECT_TO);
      }

      if (sessionStorage.getItem(NAVIGATION_HISTORY) !== null) {
        sessionStorage.removeItem(NAVIGATION_HISTORY);
      }
      this.app.state.filterByPage = [];
      this.app.filterList.field = null;
      this.app.filterList.clear();
      this.app.filterFields.quickFilterSelected = "";
      this.app.filterTree.resetFilterTree();
      this.app.filterTree.resetFiltersByPage();
    }

    // await logout();
    this.app.state.next({ model: {}, numberOfUnreadAlerts: undefined });
    await this.app.routing.navigate("login");
  }

  get landing() {
    return this.app.state.landing;
  }

  /**Get role of the logged in user */
  getCurrentUserRole() {
    if (
      this.app.auth.rolesArray.includes(
        `${this.app.customers.expectCurrent}-team`
      )
    ) {
      this.app.auth.isTeam = true;
    } else {
      this.app.auth.isTeam = false;
    }
    if (
      this.app.auth.rolesArray.includes(
        `${this.app.customers.expectCurrent}-admin`
      )
    ) {
      this.app.auth.isAdmin = true;
    } else {
      this.app.auth.isAdmin = false;
    }
    if (
      this.app.auth.rolesArray.includes(
        `${this.app.customers.expectCurrent}-user`
      )
    ) {
      this.app.auth.isUser = true;
    } else {
      this.app.auth.isUser = false;
    }
    if (
      this.app.auth.rolesArray.includes(
        `${this.app.customers.expectCurrent}-RMAdmin`
      )
    ) {
      this.app.auth.isRMAdmin = true;
    } else {
      this.app.auth.isRMAdmin = false;
    }
  }
}
