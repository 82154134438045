import { CustomerName } from "../../../../shared/config/customers";
import { DocModel, InboxType, State } from "./state";
import { Filter, FilterPage } from "../filter-list/filter-list.state";
import { getFieldIdProxy } from "../../../../shared/proxies/field-id.proxy";
import { getListIdProxy } from "../../../../shared/proxies/list-id.proxy";
import { GraphName } from "../../../../shared/types/graphName";
import { Injectable } from "@angular/core";
import { Item } from "../../../../shared/models/item";
import { Language } from "../../../../shared/settings/settings";
import { RmSection } from "../rm-submenu/rm-submenu.service.type";
import { Settings, Type } from "../../../../shared/components";
import { StateServiceType } from "./state.service.type";
import { Train } from "../../../../shared/models/train";
import { TypeIndex } from "../../../../shared/index";
import { View } from "../../../../shared/models/view";
import { PORTAL } from "../../../../shared/constants/portal";
// import { ChartType } from "chart.js";

@Injectable()
export class StateService implements StateServiceType {
  // Place to hold all app constants
  readonly LANGUAGE = "LANGUAGE";

  fieldProxy = getFieldIdProxy();
  listProxy = getListIdProxy();

  errorText = "";
  successText = "";
  language: Language = this.getLanguageChoice();
  element: Type | null = null;
  user: string | null = null;
  viewUser: string | null = null;
  customers: CustomerName[] = [];
  customer: CustomerName = PORTAL;
  passwordChangeRequired = false;
  type: Type | null = null;
  busy = false;
  id: string | null = null;
  newId: string | null = null;
  buffer: {
    [customer: string]: {
      settings: Settings;
      docs: TypeIndex<any>;
    };
  } = {};
  debugJson = false;
  model: DocModel = {};
  postModel: DocModel = {};
  userModel: DocModel = {};
  jsonModel = "{}";
  partModel: DocModel = {};
  messageModel: DocModel = {};
  omfNumber: string | null = null;
  pageSize = 25;
  focus = 0;
  importBuffer: {
    docs: TypeIndex<any>;
  } = { docs: {} };
  error = false;
  field: string | null = null;
  sortField: string | null = "thread.update_time";
  sortDirection: -1 | 1 = -1;
  sortPreference: number | null = null;
  inboxType: InboxType = "change";
  impactId: string | null = null;
  vehicleId: string | null = null;
  pcnId: string | null = null;
  profileUser: string | null = null;
  unlockedId: string | null = null;
  showHistory = false;
  selectedBox: string | null = this.listProxy.thread.main;
  errorLoginName = false;
  hasError = false;
  hasSuccess = false;
  errorLoginPassword = false;
  loginError = false;
  addNewSapDataItem = false;
  newSapDataAttribute: Item = { part: {} };
  landing: CustomerName | null = null;
  impactNameExist = false;
  cacheBuster: number = Math.random();
  partNumber: string | null = null;
  partNumbers: string[] = [];
  manufacturerId: string | null = null;
  filterByPage: Array<FilterPage> = [];
  numberOfUnreadAlerts = 0;
  numberOfUnreadChangeAlerts = 0;
  numberOfUnreadLeadTimeAlerts = 0;
  numberOfUnreadPcns = 0;
  numberOfUnreadInvMonAlerts = 0;
  numberOfChanges = 0;
  isAnalyst = false;
  isRMAdmin = false;
  allTrains: Train[] = [];
  showExternalCases = false;
  featureChart: string | null = null;
  // chartType: ChartType = "bar";
  view: View = "home";
  graphName: GraphName = GraphName.Vehicles;
  graphsSortBy: string | null = "alphabetic";
  filterOpen = false;
  filterField: string | null = null;
  filters: { [fieldId: string]: Filter } = {};
  filter: Filter = { values: new Set() };
  partsToVehicles: any[] = [];
  filterTree: any = {};
  filterCombination: { key: string; value: any }[] = [];
  // itemIndex: number | null = null;
  focusView: { type: Type; value: number }[] = [];
  createFromPart: boolean = true;

  // TODO: Necessary for accessing properties dynamically - no index signature
  [key: string]: any;

  constructor() {}

  next(state: Partial<State> = {}) {
    const newState = { ...state };
    /* This array is used to store the pages or views in which filter is applied */
    const filterViews: Set<View> = new Set(
      this.filterByPage.map((f) => f.filterView)
    );
    /* Below line of code is used to iterarte and store the views or pages where filter is applied to different pages*/

    /* Below line of code is used where in "filterByPage" array of object
        conatains all the filters applied to different pages or views */

    if (newState.view === undefined) {
      newState.view = this.view;

      if (filterViews.has(newState.view)) {
        newState.filters = {};

        this.filterByPage.forEach((view) => {
          if (
            view.filterLabelField != null &&
            view.filterView === newState.view
          ) {
            newState.filter = { values: new Set() };
            if (newState.filters) {
              newState.filters[view.filterLabelField] = {
                values: view.values,
              };
            }
          } else {
            newState.filter = { values: new Set() };
          }
        });
      }
    } else {
      if (newState.view !== this.view) {
        newState.filters = {};
        newState.filter = { values: new Set() };
      }
    }

    if (newState.language !== undefined) {
      this.setLanguageChoice(newState.language);
    }

    Object.keys(newState).forEach((key) => {
      (this as State)[key] = (newState as State)[key];
    });
  }

  setLanguageChoice(language: Language) {
    if (typeof Storage !== "undefined") {
      localStorage.setItem(this.LANGUAGE, language);
    }
  }

  getLanguageChoice(): Language {
    if (typeof Storage !== "undefined") {
      const language = localStorage.getItem(this.LANGUAGE);
      if (language != null && language !== "") {
        return language as Language;
      }
    }

    return "de";
  }
}
