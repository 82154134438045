import { Inject, Injectable } from "@angular/core";
import { AppType, APP_TYPE } from "../../app.type";
import { OCPostServiceType } from "./OC-post.service.type";
import { OCPost } from "../../../../../shared/models/obsolescence-community/OC-Post";
import { saveOCPost, updateProfileLike } from "../../../app/api.service";
import { hasChanges } from "../../utils/app.utils";
import { BehaviorSubject } from "rxjs";
import { StringUtils } from "../../../../../shared/utils/string.utils";
import { BILD_IMAGE } from "../../file/file.service";

@Injectable()
export class OCPostService implements OCPostServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  updatePostSubject: BehaviorSubject<{}> = new BehaviorSubject<any>({});

  selectedPost: OCPost = new OCPost();
  cleanPost: OCPost = new OCPost();

  image: string = "";
  externalImage: string = "";

  isReadMode: boolean = true;

  async savePost() {
    this.app.spinner.showSpinner();
    await saveOCPost(this.app.customers.expectCurrent, this.app.OCHome.postDoc);

    // Reset Model
    this.app.OCHome.postDoc = new OCPost();
    this.app.OCHome.tag = "general";
    await this.app.OCHome.ocPosts();
    await this.app.OCComment.ocCommentsByPostId();
    this.app.OCPost.updatePostSubject.next(true);
    this.app.spinner.hideSpinner();
  }

  async updatePost() {
    this.app.spinner.showSpinner();
    await saveOCPost(this.app.customers.expectCurrent, this.cleanPost);
    this.isReadMode = true;
    this.app.OCHome.editedId = "";
    await this.app.OCHome.ocPosts();
    this.app.OCHome.updatePostsSubject.next(true);
    this.app.spinner.hideSpinner();
  }

  async deletePost() {
    this.app.spinner.showSpinner();

    // Delete selected post
    this.selectedPost._deleted = true;
    this.app.OCHome.postDoc = this.selectedPost;
    await this.savePost();

    // Get the new posts
    await this.app.OCHome.ocPosts();
    this.app.spinner.hideSpinner();
  }

  async openPost(post: OCPost) {
    this.selectedPost = post;
    await this.app.OCComment.ocCommentsByPostId();
    this.app.OCPost.updatePostSubject.next(true);
    // Reset
    this.app.OCComment.commentDoc["message"] = "";

    return this.selectedPost;
  }

  getImage(customer = this.app.customers.expectCurrent) {
    if (this.app.OCPost.selectedPost == null) {
      return;
    }
    let id = this.app.OCPost.selectedPost._id;
    let encodedId = "";
    if (id != null) {
      encodedId = btoa(id);
    }
    this.image = [customer, "ocPost", encodedId, BILD_IMAGE].join("/");
    return this.image;
  }

  getImageExternal(): void {
    const { app } = this;
    const postId = this.app.OCPost.selectedPost._id;

    if (postId !== null) {
      const fileLink = this.getImage();
      const imageExists = app.file.exists(this.app.OCPost.selectedPost);
      const hasBildImage = this.hasBildImage();

      if (imageExists || hasBildImage) {
        if (fileLink != null) {
          this.externalImage = app.file.getUrl(fileLink);
        }
      } else {
        this.externalImage = !StringUtils.isNullOrEmpty(
          this.app.OCPost.selectedPost.image
        )
          ? this.app.OCPost.selectedPost.image
          : "";
      }
    }
  }

  private hasBildImage() {
    if (
      this.app.OCPost.selectedPost._attachments != null &&
      Object.keys(this.app.OCPost.selectedPost._attachments > 0)
    ) {
      const hasBildImage =
        this.app.OCPost.selectedPost._attachments[BILD_IMAGE];
      if (hasBildImage) {
        return true;
      } else {
        return false;
      }
    }
  }

  // getRouterURL(postId: string) {
  //   // this.router.navigate(["/oc-home", postId]);
  //   return `/${this.app.customers.expectCurrent}/oc-home/${postId}`;
  // }

  async isPostLikedByLoggedInUser(postId: string) {
    const profile = this.app.profile.selectedProfile;
    let likedPostsIds = profile.like;
    if (likedPostsIds != null) {
      this.app.OCHome.isLiked =
        likedPostsIds[this.app.customers.expectCurrent].includes(postId);
    }

    return this.app.OCHome.isLiked;
  }

  async likePost(postId: any) {
    const user = this.app.user;
    const customer = this.app.customers.expectCurrent;
    if (user == null) {
      return;
    }
    if (customer == null) {
      return;
    }

    let isLiked = await this.isPostLikedByLoggedInUser(this.selectedPost._id);
    this.selectedPost.isLiked = isLiked;
    this.cleanPost = this.selectedPost;
    await updateProfileLike([user], customer, postId, true);
    await this.updatePost();
    // make sure that the profile has the updated like array
    await this.app.profile.getProfile(this.app.user);
    this.app.OCHome.updatePostsSubject.next(true);
  }

  modelHasChanges() {
    const modelHasChanges = hasChanges(this.cleanPost, this.selectedPost);
    if (
      this.selectedPost["message"] != "" &&
      this.selectedPost["title"] != "" &&
      modelHasChanges
    ) {
      return false;
    } else {
      return true;
    }

    // return !hasChanges(this.cleanPost, this.selectedPost);
  }

  discardChanges() {
    this.isReadMode = true;
    this.app.OCHome.editedId = "";
  }
}
