import { Component, OnDestroy, OnInit } from "@angular/core";
import { getApp } from "../../app";
@Component({
  selector: "app-oc-comment",
  templateUrl: "./OC-comment.component.html",
  styleUrls: ["./OC-comment.component.scss"],
})
export class OCCommentComponent implements OnInit, OnDestroy {
  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {
    this.app.OCPost.updatePostSubject.subscribe(async (value) => {
      if (value) {
        this.app.attachments.OCAttachmentsSubject.next(true);
      }
    });
  }

  ngOnDestroy() {}
}
