import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { doc2Model } from "../api.service";
import { getApp } from "../app";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { TOKEN } from "../auth/token";
import { Title } from "@angular/platform-browser";
import { filter, map } from "rxjs/operators";
import { Customer } from "../../../../shared/types/customers";
import { State } from "../state/state";

@Component({
  selector: "app-main",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("unsavedChanges", { static: false }) unsavedChanges: any;

  private routeParamsSubscription: Subscription = new Subscription();
  private routeEventsSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  /**
   * Listen to the close event of the browser, if the user didn't check the 'Remember Me'
   * option on login, remove the temporary 'sessionStorage' transfer
   * @param event BeforeUnload event
   */
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event: any) {
    // event.preventDefault();
    if (typeof Storage !== "undefined") {
      if (
        localStorage.getItem(TOKEN) === null &&
        localStorage.getItem("sessionStorage") !== null
      ) {
        localStorage.removeItem("sessionStorage");
      }

      // Set 'viewRefresh' property on refresh or exit from the page - only used for 'thread-new' and 'impact'!!!
      sessionStorage.setItem("viewRefresh", "true");
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.handleRouteParams();
    this.handleRouteEvents();
    this.handleAnalystUser();
    this.app.pcnGenerator.handlePcnGeneratorRoute();
    this.handleOCRoute();

    // Get the tab title using the title defined in the routes
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          if (child == null) {
            return appTitle;
          }
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data["title"]) {
            return child.snapshot.data["title"];
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  /** Triggered when page is refreshed from the browser */
  handleRouteParams(): void {
    this.routeParamsSubscription = this.route.params.subscribe(async () => {
      await this.app.routing.decodeUrlSegments(this.router.url, this.route);
    });
  }

  /** Triggered when navigating from one page to another */
  handleRouteEvents(): void {
    this.routeEventsSubscription = this.router.events.subscribe(
      async (event) => {
        if (event instanceof NavigationEnd) {
          await this.app.routing.decodeUrlSegments(this.router.url, this.route);
          // this.showIfUnchanged();

          // remove succes/error banner and reset text variables when the url changes
          this.app.error = false;
          this.app.hasError = false;
          this.app.errorText = "";
          setTimeout(() => {
            this.app.successText = "";
            this.app.hasSuccess = false;
          }, 3000);
        }

        // show modal if the model is in edit mode and decides to leave the page and clear the model
        if (event instanceof NavigationStart) {
          if (
            event.navigationTrigger === "popstate" &&
            this.app.unlockedId !== null
          ) {
            this.app.types.forEach((type) => {
              if (this.app.unlockedId == null) {
                return;
              }
              const doc = this.app.docs.getDoc(this.app.unlockedId, type);
              const model = doc2Model(type, doc);
              Object.keys(model).forEach((key) => {
                if (model[key] !== this.app.model[key]) {
                  if (Object.keys(model).length !== 0) {
                    (this.app as any)[type].model = {};
                    this.app.unlockedId = null;
                  }
                  this.unsavedChanges.open();
                  setTimeout(() => {
                    this.modalService.dismissAll();
                  }, 1500);
                }
              });
            });
          }
        }
      }
    );
  }

  handleAnalystUser(): void {
    // Redirect Analyst user to Tree
    if (
      (this.app.state.isAnalyst || this.app.state.isRMAdmin) &&
      !this.router.url.includes("rm") &&
      !this.router.url.includes("mail") &&
      !this.router.url.includes("profile") &&
      !this.router.url.includes("part") &&
      !this.router.url.includes("manufacturer") &&
      !this.router.url.includes("matching")
    ) {
      this.app.routing.navigateRM();
    }
  }

  handleOCRoute(): void {
    if (
      this.app.customers.expectCurrent === Customer.OC &&
      !this.router.url.includes("mail") &&
      !this.router.url.includes("profile")
    ) {
      this.app.routing.navigateOCHome();
    }
  }

  ngOnDestroy(): void {
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }

    if (this.routeEventsSubscription) {
      this.routeEventsSubscription.unsubscribe();
    }
  }
}
