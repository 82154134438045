import { ComponentSettings } from "../settings/settings";

export const settings = {
  permission: {},
  text: {
    login: {
      de: "Bitte melden Sie sich an",
      en: "Please log in to continue",
      nl: "Gelieve in te loggen",
    },
    loginButton: { de: "Anmelden", en: "Log in", nl: "Aanmelden" },
    logout: { de: "Abmelden", en: "Logout", nl: "Afmelden" },
    accept: {
      de: "Mit Ihrem Login akzeptieren Sie die",
      en: "By signing in you agree to our",
      nl: "Door in te loggen gaat u akkoord met onze",
    },
    loginError: {
      de: "Name oder Passwort ist falsch.",
      en: "Name or password is incorrect.",
      nl: "Naam of Wachtwoord is niet correct.",
    },
    loginName: {
      de: "Name darf nicht leer sein.",
      en: "Name cannot be blank.",
      nl: "Er is geen Naam ingevuld.",
    },
    loginPassword: {
      de: "Passwort darf nicht leer sein.",
      en: "Password cannot be blank.",
      nl: "Er is geen Wachtwoord ingevuld.",
    },
    help: {
      de: "Benötigen Sie Hilfe bei der Anmeldung in Ihrem Account?",
      en: "Need help accessing your account?",
      nl: "Hulp nodig bij het inloggen op uw account?",
    },
  },
  list: {},
  url: {
    logo: "./assets/lcm-client-logo-rgb.png",
    landingLogo: "./assets/om-portal-logo.png",
    coopLogoLanding: "./assets/coop-logo-landing.png",
    ocLogo: "./assets/oc-logo.png",
  },
  field: {},
};

export const auth: ComponentSettings<typeof settings> = settings;
