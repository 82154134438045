import { AppType, APP_TYPE } from "../app.type";
import { Type, Docs } from "../../../../shared/components";
import { isTextSearchType } from "../../../../shared/settings/settings";
import { FilterListServiceType } from "./filter-list.service.type";
import { Filter, filterInitialState } from "./filter-list.state";
import { Inject, Injectable } from "@angular/core";
import { Customer } from "../../../../shared/types/customers";
import { getTypeaheadForFilters } from "../api.service";

@Injectable()
export class FilterListService implements FilterListServiceType {
  externalCasesDefault: boolean = false;
  mpns: string[] = [];
  cpns: string[] = [];
  pcnIds: string[] = [];
  omfNumbers: string[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  get TEXT() {
    return "_filter";
  }

  set open(filterOpen: boolean) {
    this.app.state.next({ filterOpen });
  }

  get open() {
    return this.app.state.filterOpen;
  }

  set field(field: string | null) {
    this.app.state.next({ filterField: field, filterOpen: false });
  }

  get field() {
    return this.app.state.filterField;
  }

  get fields() {
    return [null, ...Object.keys(this.app.state.filters)]
      .filter((field) => this.getValues(field).length > 0)
      .sort();
  }

  get items() {
    return this.app.state.filters;
  }

  delete(field: string | null) {
    const filter = this.get(field);
    if (field == "thread.fav") {
      this.getValues(field).forEach((value) => {
        this.get(field).values.delete(value);
      });
    }
    filter.values = new Set();
    let { filters } = this.app.state;
    if (field != null) {
      this.app.state.filterByPage.forEach((view) => {
        if (view.filterView == this.app.state.view) {
          view.filterView = null;
          view.filterLabelField = null;
          view.values = null;
        }
      });
      const filterArray = this.app.state.filterByPage;
      for (var i = filterArray.length - 1; i >= 0; i--) {
        if (filterArray[i].values == null) {
          filterArray.splice(i, 1);
        }
      }
      this.app.state.filterByPage = filterArray;
      delete filters[field];
    }
    this.app.state.next();
  }

  deleteValue(field: string | null, value: any) {
    this.get(field).values.delete(value);
    this.app.state.filterByPage.forEach((view) => {
      if (view.filterView == this.app.state.view) {
        view.filterView = null;
        view.filterLabelField = null;
        view.values = null;
      }
    });
    const filterArray = this.app.state.filterByPage;
    for (var i = filterArray.length - 1; i >= 0; i--) {
      if (filterArray[i].values == null) {
        filterArray.splice(i, 1);
      }
    }
    this.app.state.filterByPage = filterArray;
    this.app.state.next();
  }

  clear() {
    let initialState = filterInitialState;
    initialState.filters = {};
    this.app.state.next({ ...initialState, filterByPage: [] });
    this.field = null;
    this.app.model = {};
    this.app.filterFields.createFilterObject();
  }

  clearQuickFilters(type: string) {
    this.app.state.filterByPage = this.app.state.filterByPage.filter(
      (f) => f.filterLabelField !== type
    );

    let initialState = filterInitialState;
    initialState.filters = {};
    this.app.state.next({
      ...initialState,
      filterByPage: this.app.state.filterByPage,
    });

    this.field = null;
    this.app.filterFields.createFilterObject();
  }

  get isClear() {
    return this.field == null && this.fields.length === 0 && !this.open;
  }

  get(field: string | null) {
    const { items: filterItems } = this;
    if (field == null) {
      return this.app.state.filter;
    }
    return filterItems[field];
  }

  getValues(field: string | null) {
    const filter = this.get(field);
    if (filter == null) {
      return [];
    }
    return Array.from(filter.values).sort();
  }

  touch(field: string | null) {
    const { items: filterItems } = this;
    if (field == null) {
      return this.app.state.filter;
    }
    let filter = filterItems[field];
    if (filter == null) {
      filter = { values: new Set() };
      filterItems[field] = filter;
    }
    return filter;
  }

  getValue(field = this.field) {
    if (field == null) {
      field = this.TEXT;
    }
    return this.app.model[field];
  }

  add() {
    const { field } = this;
    const { model } = this.app;
    const value = this.getValue(field);
    const filter = this.touch(field);
    if ((value == null || value === "") && field == null) {
      return;
    }

    filter.values.add(value);

    this.app.state.filterByPage.push({
      values: filter.values,
      filterView: this.app.state.view,
      filterLabelField: field,
    });
    this.app.filterFields.createFilterObject();
    this.app.paginator.focus = 0;
    this.app.paginator.pageSize = this.app.state.pageSize;
    delete model[field != null ? field : this.TEXT];
    this.app.state.next({ filterField: null });
  }

  get showExternalCases() {
    return this.app.state.showExternalCases;
  }

  async toggle() {
    this.app.state.next({
      showExternalCases: !this.app.state.showExternalCases,
    });
    this.externalCasesDefault = this.app.state.showExternalCases;
    this.app.spinner.showSpinner();
    await this.app.thread.getExternalCases();
    this.app.spinner.hideSpinner();
    // this.app.filterFields.createFilterObject();
  }

  /** Creates a filter function based on the current filter items */
  getFunction<T extends Type>(type: T): (doc: Docs[T]) => boolean {
    const filterFunctions: ((doc: Docs[T]) => boolean)[] = [];
    // filter items are introduced in the search bar
    // will result the docs that matches the value of the item
    {
      const filterValues = this.getValues(null);
      if (filterValues.length > 0) {
        const textSearchProperties = this.app.field
          .getAll(type)
          .filter(
            (field) =>
              isTextSearchType(this.app.field.getType(field)) &&
              this.app.field.getFieldSettings(field).search !== false
          )
          .map((fieldId) => fieldId.split(".")[1]);
        filterFunctions.push((doc: Docs[T]) => {
          for (const filterValue of filterValues) {
            for (const property of textSearchProperties) {
              let fieldValue: string = (<any>doc)[property];
              if (fieldValue == null || fieldValue === "") {
                continue;
              }
              fieldValue = fieldValue.toLowerCase();
              fieldValue = (fieldValue as string).toLowerCase();
              if (fieldValue.indexOf(filterValue.toLowerCase()) > -1) {
                return true;
              }
            }
          }
          return false;
        });
      }
    }
    // filter items are introduced in the in the fields filtration
    // will result the docs that matches the value of the item
    this.app.field.getAll(type).forEach((fieldId) => {
      const filterValues = this.getValues(fieldId);
      /** exception added for the thread.omApproach since it has to look for threads that have the omApproach set as an empty string  */
      if (
        fieldId === "thread.omApproach" &&
        filterValues.includes("noApproach")
      ) {
        filterValues[0] = filterValues[0].replace("noApproach", "");
      }

      if (filterValues.length < 1) {
        return;
      }
      const [, fieldName] = fieldId.split(".");
      if (fieldName !== "fav") {
        filterFunctions.push((doc: any) => {
          let docValue: any;
          /** exception added for the impact.artNumber since it has to look for a different property */
          if (fieldId === "impact.artNumber") {
            docValue = doc["omfVehicleName"];
          } else {
            docValue = doc[fieldName];
          }
          for (const filterValue of filterValues) {
            if (this.doesFieldMatchValue(fieldId, docValue, filterValue)) {
              return true;
            } else {
              continue;
            }
          }
          return false;
        });
      }
    });

    // display all docs that were found previously and have been returned to be true - which means that it contain the value searched
    const filterFunction = (doc: Docs[T]) => {
      // additional check for threads to display internal/external cases
      if (type === "thread") {
        const thread = <Docs["thread"]>doc;
        if (
          this.app.state.showExternalCases === false &&
          thread.caseSource === "extern" &&
          this.app.customer === Customer.OMP
        ) {
          return false;
        }
      }
      for (const fieldFilterFunction of filterFunctions) {
        // all docs that are type attachment needs to be ignored when comparing with the item introduced in order to display the images
        if (type === "attachment") {
          continue;
        }
        if (!fieldFilterFunction(<any>doc)) {
          return false;
        }
      }
      return true;
    };
    return filterFunction;
  }

  /** returns true if the docValue matches the filterValue depending on the field type */
  doesFieldMatchValue(
    fieldId: string,
    docValue: any,
    filterValue: any
  ): boolean {
    try {
      const fieldType = this.app.field.getType(fieldId);
      const isMultiple = this.app.field.isMultiple(fieldId);
      switch (fieldType) {
        case "value":
        case "number":
        case "text":
        case "typeahead":
          const fieldValue = docValue.toLowerCase();
          return fieldValue.indexOf(filterValue.toLowerCase()) > -1;

        case "options":
          if (isMultiple) {
            return docValue.some((x: any) => filterValue.includes(x));
          }
          if (!isMultiple) {
            if (fieldId === "user.roles") {
              filterValue = [
                `${this.app.customers.expectCurrent}-` + filterValue,
              ];
              return docValue.some((x: any) => filterValue.includes(x));
            }
          }
          return docValue.toLowerCase() === filterValue.toLowerCase();
        //default value would be used by: own cases, favorite cases
        default:
          return docValue === filterValue;
      }
    } catch {
      return false;
    }
  }

  createFilter(field: string | null, initFilter: (filter: Filter) => void) {
    const filter = this.touch(field);
    this.app.state.filterByPage.push({
      values: filter.values,
      filterView: this.app.state.view,
      filterLabelField: field,
    });
    initFilter(filter);
    this.app.filterFields.createFilterObject();
    this.app.state.next({});
  }

  getBoxFields(box: string) {
    try {
      const fields = this.app.getList(box);
      /* sorting the filter options alphabetically */
      fields.sort((a: string, b: string): number => {
        /* changing the case (to upper or lower) ensures a case insensitive sort*/
        let textA = this.app.field.getLabel(a).toUpperCase();
        let textB = this.app.field.getLabel(b).toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      if (fields == null) {
        return [];
      }
      if (box === "thread.fieldsOnFilter") {
        let index = fields.findIndex(
          (value: string) => value === "thread.omfNumber"
        );
        fields.splice(index, 1);
        fields.unshift("thread.omfNumber");
      }
      return fields;
    } catch (err) {
      return [];
    }
  }

  async getTypeaheadOptionsForFilters() {
    const { omfNumbers } = await getTypeaheadForFilters(
      this.app.customers.expectCurrent
    );
    this.omfNumbers = omfNumbers;
  }
}
