import { ActivatedRoute } from "@angular/router";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DocModel } from "../state/state";
import { getApp } from "../app";
import { model2Doc } from "../api.service";
import { Subscription } from "rxjs";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  @Input() selectedProfile = "";

  @ViewChild("fileInput", { static: false }) fileInput: any;
  profileName = "";
  model: DocModel = {};
  private _routeSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });
  showButton = true;

  personProfileFields: string[] = [];

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.personProfileFields = this.app.list.personProfile.personProfile;
    // this.app.profile.user = this.selectedProfile;
    this._routeSubscription = this.route.params.subscribe(async (params) => {
      this.profileName = params.user;
      this.app.profile.userProfilePicture =
        await this.app.profile.getProfilePicture(this.profileName);
    });
    let routeIndex = this.app.routing.navigationHistory.findIndex((r) =>
      r.includes("profile-password")
    );
    if (routeIndex !== -1) {
      this.showButton = false;
    }

    if (this.app.customers.expectCurrent === Customer.OC) {
      this.app.OCHome.setBadgeSystem();
      if (!this.app.auth.isAdmin) {
        this.personProfileFields.splice(
          this.personProfileFields.indexOf("personProfile.Management", 1)
        );
      }
    }
  }

  get name(): string {
    const profileUser = this.app.profile.user;
    if (profileUser != null) {
      return profileUser;
    }
    return this.app.expectUser;
  }

  get own() {
    return this.name === this.app.user;
  }

  checkPermissions(name: string) {
    switch (this.app.customers.expectCurrent) {
      case Customer.OC:
        return this.app.permission.user.manage;
      default:
        if (name !== this.app.user) {
          return false;
        } else {
          return true;
        }
    }

    // if (name !== this.app.user) {
    //   return false;
    // } else {
    //   return true;
    // }
    // if (this.app.users.hasEditPermission(name)) {
    //   return true;
    // }
  }

  async onUpload() {
    const file: File = this.fileInput.nativeElement.files[0];
    this.app.profile.uploadProfilePicture(file);
  }

  async onDelete() {
    await this.app.profile.deleteProfilePicture();
    this.fileInput.nativeElement.value = "";
  }

  async save() {
    const { app } = this;
    const model = this.app.users.model;
    let user = app.expectUser;
    const profile = app.profile.selectedProfile;

    // if a user has a team/admin role he should be able to edit another user profile
    if (user != profile._id) {
      user = profile._id;
    }

    const personProfile = model2Doc("personProfile", model);
    const organizationProfile = model2Doc("organizationProfile", model);

    profile.data = this.app.profile.getProfileDataJson(
      personProfile,
      organizationProfile
    );
    delete profile.sections;

    await this.app.profile.updateProfile(user, profile);

    this.app.profile.selectedProfile = await this.app.profile.getProfile(user);
    this.app.users.getUserModelClean();
    this.app.userSettings.updateSettings();
    if (this.app.customers.expectCurrent === Customer.OC) {
      this.app.OCHome.setBadgeSystem();
    }
    // await this.app.routing.navigateProfile(user);
  }

  discardDetails() {
    this.app.unlockedId = null;
    this.app.users.getUserModelClean();
    this.app.userSettings.currentUserEmailPreferences = [
      ...this.app.userSettings.cleanUserEmailPreferences,
    ];
  }

  showEmailsBox() {
    if (
      this.app.auth.isAdmin ||
      this.app.users.currentUser.name === this.profileName
    ) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }
}
