import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { doc2Model, model2Doc, saveUser } from "../api.service";
import { passwordPolicy, checkPasswordPolicy } from "./password-policy";
import { DocModel } from "../state/state";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
})
export class PasswordComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });
  passwordPolicy = passwordPolicy;
  constructor() {}

  async ngOnInit() {
    if (this.app.user) {
      await this.app.users.getUserById(this.app.user);
      this.app.users.getUserModelClean();
      let userClean = doc2Model("user", this.app.users.currentUser);
      this.app.state.next({ userModel: userClean });
    }
  }

  get requiredFields() {
    this.app.field.resetAllRequiredFieldSettings("user");
    this.app.field.getFieldSettings("user.name").required = false;
    this.app.field.getFieldSettings("user.roles").required = false;
    this.app.field.getFieldSettings("user.oldPassword").required = true;
    this.app.field.getFieldSettings("user.password").required = true;
    this.app.field.getFieldSettings("user.retypePassword").required = true;
    let requiredFields = this.app.field.getInvalidFields(
      "user",
      this.app.model
    );
    return requiredFields;
  }

  async save() {
    // update password expiry date on update of password
    const d = new Date();
    d.setMonth(d.getMonth() + 6);
    const nextExpireDate = d.toISOString().slice(0, 10);

    const { model, user } = this.app;

    if (user == null) {
      throw new Error("user name == null");
    }
    if (this.app.user == null) {
      throw new Error("user not found");
    }
    let result: any = {};

    const currentModel = model2Doc("user", model);
    const currentDoc = this.app.users.currentUser;
    const newDoc = doc2Model("user", currentDoc);
    newDoc["user.password"] = currentModel.password;
    newDoc["user.oldPassword"] = currentModel.oldPassword;
    newDoc["user.retypePassword"] = currentModel.retypePassword;
    newDoc["user.passwordExpireDate"] = nextExpireDate;
    let updatedFieldsDoc = model2Doc("user", newDoc);
    let passwordPolicy = this.checkPassword(newDoc);
    if (passwordPolicy === "No error") {
      let passwordValid = await this.app.auth.testPassword(
        user,
        currentModel.oldPassword
      );
      if (!passwordValid) {
        this.app.hasError = true;
        this.app.errorText = this.app.text.profilePassword.oldPasswordIncorrect;
      }
      if (passwordValid) {
        updatedFieldsDoc.forcePasswordChangeOnFirstLogin = false;
        // Delete oldPassword & retypePassword because we do not want to save them in the database
        delete updatedFieldsDoc.oldPassword;
        delete updatedFieldsDoc.retypePassword;
        result = await saveUser(
          this.app.customers.expectCurrent,
          updatedFieldsDoc
        );
        if (!result.error) {
          this.app.unlockedId = null;
          this.app.hasSuccess = true;
          this.app.hasError = false;
          this.app.successText =
            this.app.text.profilePassword.succesUserUpdated;
          this.app.routing.navigateProfile(user);
          this.app.clearModel();
        } else {
          this.app.hasSuccess = false;
          this.app.hasError = true;
          this.app.errorText = this.app.text.user.passwordUsed;
        }
      }
    } else {
      return;
    }
    if (!result.error && !this.app.hasError) {
      this.app.users.currentUser = result;
    }
  }

  checkPassword(model: DocModel) {
    const result = checkPasswordPolicy(this.app.text, model, "user");
    if (result !== "No error") {
      this.app.hasError = true;
      this.app.errorText = result;
    }
    return result;
  }

  get listOfFields() {
    const boxes = this.app.list.profilePassword.boxes;
    let list: string[] = [];

    boxes.forEach((box) => {
      list = [...this.app.getList(box)];
    });
    return list;
  }
  discardChanges() {
    if (this.app.user == null) {
      return;
    }
    this.app.routing.navigateProfile(this.app.user);
  }
}
