import { Component, ViewChild, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { getApp } from "../app";
import { Customer } from "../../../../shared/types/customers";
import { PlatformLocation } from "@angular/common";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent {
  @Input() selectedProfile = "";
  /** title of the modal */
  @Input() modalTitle = "";
  /** message / body of the modal */
  @Input() modalMessage = "";
  /** the url of an image that has to be displayed in the modal */
  @Input() imageUrl = "";
  /** show Cancel & Save buttons in the foorter of the modal */
  @Input() showButtons = true;
  /** show dismiss button in the header of the modal */
  @Input() showDismiss = true;
  /** show only ok button in the foorter of the modal */
  @Input() showOnlyOkBtn = false;
  /** display different text based on the action  */
  @Input() showOMWarning: boolean = false;
  /** display different content based on the type of the modal */
  @Input() modalType:
    | "replacements"
    | "svhcItems"
    | "pcnHistory"
    | "showWhereUsed"
    | "showListOfCases"
    | "dueDateField"
    | "image"
    | "wf-automation"
    | "vehicle-responsible"
    | "vehicle-responsible-new"
    | "userManuals"
    | "profileInfo"
    | "assignedTo"
    | "" = "";
  @Output() ok = new Subject<any>();
  currentPart: string = "";

  constructor(
    private modalService: NgbModal,
    private platformLocation: PlatformLocation
  ) {
    // closes modal when back button is clicked
    platformLocation.onPopState(() => this.modalService.dismissAll());
  }

  @ViewChild("content", { static: false }) content: any;
  app = getApp((app) => {
    this.app = app;
  });

  async open() {
    try {
      let result: any;
      let modalSize = this.getSizeOfModal();

      result = await this.modalService.open(this.content, {
        windowClass: modalSize,
      }).result;
      this.ok.next(result);
    } catch (err) {}
  }

  getSizeOfModal() {
    switch (this.modalType) {
      case "replacements":
      case "pcnHistory":
        return "xlModal";
      case "svhcItems":
      case "wf-automation":
      case "vehicle-responsible":
      case "vehicle-responsible-new":
        return "lg";
      case "assignedTo":
      case "showWhereUsed":
        return "mediumModal";
      default:
        return "smallModal";
    }
  }

  /** get text of the buttons based on the action */
  getButtonsText(button: string) {
    switch (button) {
      case "dismiss":
        if (this.showOMWarning) {
          return this.app.text.app.abort;
        } else {
          return this.app.text.modal.cancel;
        }
      case "save":
        if (this.showOMWarning) {
          return this.app.text.app.confirm;
        } else {
          return this.app.text.modal.ok;
        }
    }
  }

  dismiss() {
    if (
      this.modalType === "dueDateField" &&
      this.app.customers.expectCurrent === Customer.MAQUETCARDIO
    ) {
      this.app.thread.thread[this.app.fieldId.thread.dueDate] =
        this.app.thread.cleanModel["thread.dueDate"];
    }
    if (this.app.thread.isNew) {
      this.modalService.dismissAll();
    } else {
      // this.app.unlockedId = null;
      this.app.typeAheadBasic.model = {};
      this.modalService.dismissAll();
    }
  }

  // closeModal() {
  //   if (this.app.thread.isNew) {
  //     this.app.state.createFromPart = true;
  //     this.modalService.dismissAll();
  //   } else {
  //     // this.app.unlockedId = null;
  //     this.app.state.createFromPart = true;
  //     this.modalService.dismissAll();
  //   }
  // }

  closeModal() {
    this.modalService.dismissAll();
    if (
      this.modalType === "wf-automation" ||
      this.modalType === "vehicle-responsible" ||
      this.modalType === "vehicle-responsible-new"
    ) {
      this.app.unlockedId = null;
      this.app.typeAheadBasic.model = {};
    }
  }

  isDisabled() {
    if (
      (this.app.thread.thread[this.app.fieldId.thread.dueDate] == null ||
        this.app.thread.thread[this.app.fieldId.thread.dueDate] === "") &&
      this.modalType === "dueDateField"
    ) {
      return true;
    } else {
      return false;
    }
  }
}
