<input
  #fileInput
  type="file"
  accept="image/*"
  style="display: none"
  (change)="onUpload()"
/>

<app-modal
  #deleteProfilePhotoModal
  [modalTitle]="app.text.app.confirm"
  [modalMessage]="app.text.app.deleteProrfilePicture"
  (ok)="onDelete()"
>
</app-modal>

<app-modal
  #discardChangesModal
  [modalMessage]="app.text.profile.discard"
  [modalTitle]="app.text.profile.modalTitle"
  (ok)="discardDetails()"
>
</app-modal>

<div class="row">
  <div class="col-xl-3 col-md-12 col-sm-12">
    <div class="card mt-3">
      <div class="card-header p-2">
        <div class="d-flex justify-content-between">
          <span class="pt-1">
            {{ app.text.profile.profileImg }}
          </span>
          <div>
            <button
              *ngIf="
                own &&
                app.profile.userProfilePicture === '' &&
                checkPermissions(name)
              "
              class="btn btn-sm btn-image"
              (click)="fileInput.click()"
              [title]="app.text.profile.uploadImage"
              [disabled]="app.unlockedId != null"
            >
              <i class="fa fa-upload"></i>
            </button>
            <button
              *ngIf="
                own &&
                app.profile.userProfilePicture !== '' &&
                checkPermissions(name)
              "
              class="btn btn-sm btn-image"
              (click)="deleteProfilePhotoModal.open()"
              [title]="app.text.profile.deleteImage"
              [disabled]="app.unlockedId != null"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="name != null">
        <div class="text-center">
          <img
            *ngIf="app.profile.userProfilePicture != ''"
            class="userImage"
            target="_blank"
            [src]="app.file.getUrl(app.profile.userProfilePicture)"
          />
          <img
            *ngIf="app.profile.userProfilePicture === ''"
            class="userImage"
            target="_blank"
            [src]="app.url.profile.defaultImage"
          />
        </div>
        <div class="text-center my-2">
          {{ name }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-9 col-md-12 col-sm-12">
    <div class="card pb-2 mt-3">
      <div class="card-header p-2">
        <div class="d-flex justify-content-between">
          <span class="pt-1">{{ app.text.profile.profileInfo }}</span>
          <div class="controls">
            <app-unlock [id]="name" [permission]="checkPermissions(name)">
              <button
                class="btn btn-sm btn-save"
                (click)="save(); app.unlockedId = null"
                [disabled]="app.field.getInvalidFields('profile').length > 0"
              >
                <i class="fa fa-check"></i> {{ app.text.app.save }}
              </button>
              <button
                class="btn btn-sm btn-clean"
                (click)="discardChangesModal.open()"
                [title]="app.text.profile.close"
              >
                <i class="fas fa-times close-icon"></i>&nbsp;
                {{ app.text.post.cancel }}
              </button>
            </app-unlock>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <app-field
            *ngFor="let field of personProfileFields; index as index"
            [model]="app.users.model"
            [field]="field"
            [index]="index"
            [publicFieldName]="app.fieldId.personProfile.publicFields"
            [disabled]="app.isLocked(name)"
            [noStriped]="true"
            [shadowInput]="true"
          ></app-field>
        </div>
        <div class="col-6">
          <app-field
            *ngFor="
              let field of app.list.organizationProfile.organizationProfile;
              index as index
            "
            [model]="app.users.model"
            [field]="field"
            [index]="index"
            [publicFieldName]="app.fieldId.organizationProfile.publicFields"
            [disabled]="app.isLocked(name)"
            [noStriped]="true"
            [shadowInput]="true"
          ></app-field>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Badge system -->
<div class="row" *ngIf="app.customer === 'oc'">
  <div class="col-xl-3 col-md-12 col-sm-12">
    <div class="card mt-3">
      <div class="card-header p-2">My badges</div>
      <div *ngFor="let badge of app.OCHome.badges" class="m-2">
        <i class="fa-solid fa-medal fa-fw" [style.color]="badge.color"></i>
        <span class="label"> {{ badge.text.en }}</span>
      </div>
    </div>
  </div>
</div>

<!-- //Settings -->
<div class="row" *ngIf="showEmailsBox()">
  <div
    [ngClass]="{
      'col-md-12 col-sm-12': app.customer !== 'oc',
      'col-xl-3 col-md-12 col-sm-12': app.customer === 'oc'
    }"
  >
    <app-user-settings></app-user-settings>
  </div>
</div>

<div class="row">
  <div class="col-xl-3 col-md-12 col-sm-12">
    <div class="card mt-3">
      <div *ngIf="own && app.unlockedId === null && app.customer !== 'oc'">
        <div class="card-header p-2">{{ app.text.profile.language }}</div>
        <div
          class="languages-container m-2"
          *ngIf="app.profile.user === app.user"
        >
          <div class="btn-group" *ngFor="let language of app.languages">
            <button
              *ngIf="app.customer !== 'ns' && language !== 'nl'"
              class="btn btn-sm language-btn"
              [ngClass]="{ 'selected-language': language === app.language }"
              (click)="app.language = language; app.users.setLanguage(language)"
            >
              {{ language | uppercase }}
            </button>
            <button
              *ngIf="app.customer === 'ns'"
              class="btn btn-sm language-btn"
              [ngClass]="{ 'selected-language': language === app.language }"
              (click)="app.language = language; app.users.setLanguage(language)"
            >
              {{ language | uppercase }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-9 col-md-12 col-sm-12">
    <div *ngIf="own && app.unlockedId === null && app.customer !== 'oc'">
      <app-password></app-password>
    </div>
  </div>
</div>

<!-- TODO: unsure if it's still necessary -->
<!-- <div *ngIf="own" class="row">
  <div class="col-md-12 p-0">
    <app-box
      [box]="app.listId.profile.fields"
      [model]="app.users.model"
      [unlock]="name"
    >
      {{ language | uppercase }}
    </button>
  </div>
</div> -->
