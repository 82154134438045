import { APP_TYPE, AppType } from "../app.type";
import { Docs } from "../../../../shared/components";
import {
  getProfile,
  getProfiles,
  updateProfile,
  updateProfileFavorite,
} from "../api.service";
import { Inject, Injectable } from "@angular/core";
import { Profile } from "../../../../shared/models/profile";
import { ProfileField, ProfileServiceType } from "./profile.service.type";
import { BehaviorSubject } from "rxjs";
import { Attachment } from "../attachments/attachments.utils";

@Injectable()
export class ProfileService implements ProfileServiceType {
  profiles: Profile[] = [];
  ids: string[] = [];
  attachments = new Attachment();
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  ownProfile: Profile = {} as Profile;
  ownProfilePicture: BehaviorSubject<string> = new BehaviorSubject("");
  selectedProfile: Profile = {} as Profile;
  profileImage: string | null = "";
  userProfilePicture: string | null = "";

  // user: string = "";

  get user() {
    return this.app.state.profileUser;
  }

  async getPictureForLoggedInUser(user = this.app.user) {
    if (user == null) {
      return;
    }

    const customer = this.app.customers.expectCurrent;
    const attachments = await this.app.file.getAttachmentsByDocType(
      user,
      "profile"
    );

    //if no files were attached to the profile, an object which has the length property 0 will be returned
    if (Object.keys(attachments).length === 0) {
      this.profileImage = null;
      return;
    }

    const fileName = Object.keys(attachments)[0];
    const fileLink = [customer, "profile", user, fileName].join("/");
    this.profileImage = fileLink;
  }

  async getProfilePicture(user: string | null) {
    if (user == null) {
      return null;
    }
    const customer = this.app.customers.expectCurrent;
    this.attachments = await this.app.file.getAttachmentsByDocType(
      user,
      "profile"
    );

    //if no files were attached to the profile, an object which has the length property 0 will be returned
    let fileLink = "";
    if (Object.keys(this.attachments).length !== 0) {
      const fileName = Object.keys(this.attachments)[0];
      fileLink = [customer, "profile", user, fileName].join("/");
    }
    return fileLink;
  }

  async getProfile(user = this.app.user): Promise<Profile> {
    if (user == null) {
      return {} as Profile;
    }

    // check
    this.selectedProfile = await getProfile(user);
    return this.selectedProfile;
  }

  async getProfiles(userNames: string[]) {
    this.profiles = await getProfiles(userNames);
    this.ids = this.profiles.map((p) => p._id);
    return this.profiles;
  }

  async updateProfile(user = this.app.user, profile: Profile): Promise<void> {
    if (user == null) {
      return;
    }

    await updateProfile(user, profile);
  }

  async updateMultipleFavorite(
    profileIds: string[],
    customer = this.app.customers.expectCurrent,
    threadId: string,
    toggle: boolean
  ): Promise<void> {
    await updateProfileFavorite(profileIds, customer, threadId, toggle);
  }

  getProfileDataJson(
    pers: Docs["personProfile"],
    org: Docs["organizationProfile"]
  ): string {
    const persFieldList: ProfileField[] = [];
    const orgFieldList: ProfileField[] = [];

    Object.keys(pers)
      .filter((key) => key !== "publicFields")
      .forEach((key) => {
        persFieldList.push({
          title: key,
          value: (pers as any)[key],
          // checked: pers.publicFields.includes(key),
        });
      });

    Object.keys(org)
      .filter((key) => key !== "publicFields")
      .forEach((key) => {
        orgFieldList.push({
          title: key,
          value: (org as any)[key],
          // checked: org.publicFields.includes(key),
        });
      });

    const profileData = {
      pers: persFieldList,
      org: orgFieldList,
    };

    return JSON.stringify(profileData);
  }

  async uploadProfilePicture(file: File) {
    const { customer, user } = this.app;
    if (customer == null) {
      return;
    }
    if (user == null) {
      return;
    }
    if (file != null && file.type.indexOf("image/") !== 0) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.thread.uploadImageTypeError;
    } else {
      this.app.hasError = false;
      this.app.errorText = "";
      let fileLink = [customer, "profile", btoa(user), "user.jpg"].join("/");
      await this.app.file.upload(file, fileLink);
      //decode userid to get the profile picture
      fileLink = [customer, "profile", user, "user.jpg"].join("/");
      this.ownProfilePicture.next(fileLink);
      this.userProfilePicture = fileLink;
    }
  }

  async deleteProfilePicture() {
    const { customer, user } = this.app;
    if (customer == null || user == null) {
      return;
    }
    // const image = this.app.profile.profileImage;
    // if (!image) {
    //   return;
    // }
    // for delete the doc id (user) must be encoded since a general api is used
    const fileLink = [customer, "profile", btoa(user), "user.jpg"].join("/");
    /** !!! DELETE any attachment that has been added to the profiles independent on the name !!!  */
    await this.app.file.delete(fileLink);
    this.ownProfilePicture.next("");
    this.userProfilePicture = "";
  }
}
