<!-- New summury version -->
<div
  (click)="app.OCPost.openPost(post)"
  class="card post-card pointer m-1 p-2"
  [ngClass]="{ 'selected-post': post === app.OCPost.selectedPost }"
>
  <div class="row">
    <!-- Image -->
    <!-- <div class="col-md-1 img-container">
      <img
        *ngIf="post.avatar != ''"
        class="profile-image-sm"
        target="_blank"
        [src]="app.file.getUrl(post.avatar)"
      />
      <img
        *ngIf="post.avatar === ''"
        class="profile-image-sm"
        target="_blank"
        [src]="app.url.profile.defaultImage"
      />
    </div> -->
    <!-- Details -->

    <!-- First line -->
    <div class="d-flex flex-wrap">
      <div class="me-1">
        <img
          *ngIf="post.avatar != ''"
          class="profile-image-sm"
          target="_blank"
          [src]="app.file.getUrl(post.avatar)"
        />
        <img
          *ngIf="post.avatar === ''"
          class="profile-image-sm"
          target="_blank"
          [src]="app.url.profile.defaultImage"
        />
      </div>
      <!-- <b>
        <span class="text">{{ post.userFirstName }}</span>
        <span class="text">&nbsp;{{ post.userLastName }}</span>
      </b> -->
      <b
        ><span class="text">{{ post.user_id }}</span></b
      >
      <!-- <div class="credits py-1">
          <i>
            <span>&nbsp;{{ post.userRole }}</span
            ><span>&nbsp;@</span>
            <span>&nbsp;{{ post.userCompany }}</span>
          </i>
        </div> -->
      <div class="mx-1" *ngFor="let badge of post.userBadges">
        <i
          class="fa-solid fa-medal fa-fw"
          [style.color]="app.OCHome.setBadgeColor(badge)"
          [ngbTooltip]="app.OCHome.setBadgeTooltip(badge)"
        ></i>
      </div>
      <div
        class="credits tag p-1 ms-auto"
        [ngStyle]="
          app.OCPostItem.getTagColor(app.fieldId.ocPost.tagCategory, post.tag)
        "
      >
        {{ app.field.getOptionText(app.fieldId.ocPost.tagCategory, post.tag) }}
      </div>
    </div>
    <!-- Second line -->
    <b>
      <span class="text">{{ post.title }}</span>
    </b>
    <!-- 3th line -->
    <div
      class="text post-text"
      [innerHtml]="app.field.highlightLinks(post.message)"
    >
      {{ post.message }}
    </div>
    <!-- 4th line -->
    <div class="d-flex mt-1">
      <!-- Commnets -->
      <div class="px-1">
        <i class="fa-regular fa-comment controls me-2"></i>
        <span class="controls-text">{{ post.counterComment }}</span>
      </div>
      <!-- Likes -->
      <div class="px-1">
        <i
          *ngIf="!post.isLikedByLoggedInUser"
          class="fa-regular fa-thumbs-up controls me-2"
        ></i>
        <i
          *ngIf="post.isLikedByLoggedInUser"
          class="fa-solid fa-thumbs-up controls me-2 liked"
        ></i>
        <span [ngClass]="{ liked: post.isLiked }" class="controls-text">
          {{ post.counterLikes }}
        </span>
      </div>
      <!-- Time info -->
      <!-- <div class="controls-text ms-auto">
        {{ app.OCHome.getTimeInfo(post.create_time) }}
      </div> -->
      <div class="controls-text ms-auto">
        <span>Posted on {{ app.OCHome.getTimeInfo(post.create_time) }}</span>
        <span *ngIf="post.update_time != post.create_time"
          >&nbsp;Edited on {{ app.OCHome.getTimeInfo(post.update_time) }}</span
        >
      </div>
    </div>
  </div>
</div>
