import { AppType, APP_TYPE } from "../app.type";

import { Inject, Injectable } from "@angular/core";
import { FilterFieldsServiceType } from "./filter-fields.service.type";
import { Thread } from "../../../../shared/models/thread";
import {
  getDocsByType,
  getTaskByTab,
  getUncompletedTasksByDeputy,
} from "../api.service";
import { Post } from "../../../../shared/models/post";
import { FilterPage } from "../filter-list/filter-list.state";
import { User } from "../../../../shared/models/user";
import { Customer } from "../../../../shared/types/customers";
import { VehicleResponsible } from "../../../../shared/models/vehicleResponsible";
import { Task } from "../../../../shared/models/task";
import { MY_TASKS } from "../tasks/tasks.service";

const OFFER = "offer";
const SEEK = "seek";
@Injectable()
export class FilterFieldsService implements FilterFieldsServiceType {
  quickFilterText: string = this.app.text.home.showAllCases;
  quickFilterSelected: string = "";
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  filterValues: any = {};

  createFilterObject() {
    this.app.spinner.showSpinner();
    this.getFilterResult();
  }

  async getFilterResult() {
    /** create a filter function on a specific type(eg: Thread / Post etc) */
    let filter = this.app.filterList.getFunction(this.app.type);
    switch (this.app.type) {
      case "thread":
        /**handle special logic because the on homepage we have the possibility to filter by properties from different documents type */
        let threads = await this.getCasesSpecialFilter();
        /** set the list of resulted ids in order to set the result of the filtering -> tables use the ids  */
        this.app.thread.ids = threads.map((t) => t._id);
        break;
      case "post":
        /**apply filter on  post type documents */
        let posts = this.app.post.posts.filter((p) => filter(p));
        /** set the list of resulted ids in order to set the result of the filtering -> tables use the ids  */
        this.app.post.ids = posts.map((p) => p._id);
        break;
      case "user":
        let users = this.app.users.users.filter((u) => filter(u));
        this.app.users.userIds = users.map((u) => u._id);
        this.app.spinner.hideSpinner();
        break;
      case "vehicleResponsible":
        let vehicles = await this.getResponsiblesSpecialFilter();
        let vehicleIds = new Set<string>();

        vehicles.forEach((vehicle) => {
          if (vehicle._id != null) {
            vehicleIds.add(vehicle._id);
          }
        });
        this.app.vehicleResponsible.ids = Array.from(vehicleIds);

        // this.app.vehicleResponsible.ids = vehicles.map((v) => v._id !== null ? v._id : "");
        this.app.spinner.hideSpinner();
        break;
      case "personProfile":
        filter = this.app.filterList.getFunction("user");
        let profiles = this.app.users.usersCompressed.filter((u) => filter(u));
        let names = profiles.map((p: Partial<User>) => p.name);
        let matchedProfiles = this.app.profile.profiles.filter((p) =>
          names.includes(p._id)
        );
        this.app.profile.ids = matchedProfiles.map((p) => p._id);
        this.app.spinner.hideSpinner();
        break;
    }
  }

  async getCasesSpecialFilter() {
    /**new functionality */
    /**this.app.filterByPage stores all the current selected filters */
    /** the following checks are done to distinguish the type of filters */
    let threadFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField != null &&
          f.filterLabelField.includes("thread") &&
          !f.filterLabelField.includes("fav")
      ) !== -1;
    let postFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField != null &&
          f.filterLabelField.includes("post")
      ) !== -1;
    let impactFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField != null &&
          f.filterLabelField.includes("impact")
      ) !== -1;

    let favFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField != null &&
          f.filterLabelField.includes("fav")
      ) !== -1;

    let openCasesFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null && f.filterLabelField !== null && f.values.has("openCases")
      ) !== -1;

    let closedCasesFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField !== null &&
          f.values.has("closedCases")
      ) !== -1;

    /** filter added only for DB - should bring the list of cases where the current user is deputy/"Vertreter" and has to give feedback*/
    let deputyFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null && f.filterLabelField !== null && f.values.has("deputy")
      ) !== -1;

    /** filter added only for DB - should bring the list of cases where the current user is deputy/"Vertreter" and has to give feedback*/
    let personalFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null && f.filterLabelField !== null && f.values.has("personal")
      ) !== -1;

    /** filter added only for DB - should bring the list of cases where the current user is deputy/"Vertreter" independent of the status of the  case*/
    let myResponsiblityFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField !== null &&
          f.values.has("responsible")
      ) !== -1;

    let trainFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField != null &&
          f.filterLabelField.includes("train")
      ) !== -1;

    /** filter added only for DB - > should bring the list of cases containing a specific internalItem.partNumber */
    let itemFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField != null &&
          f.filterLabelField.includes("internalItem")
      ) !== -1;

    /** filter added only for COOP - should bring the list of cases where the type of post is Offer */
    let offerFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField != null &&
          f.filterLabelField.includes(OFFER)
      ) !== -1;

    /** filter added only for COOP - should bring the list of cases where the type of post is Seek */
    let seekFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField != null &&
          f.filterLabelField.includes(SEEK)
      ) !== -1;

    /** get filter function for each type of filtering-type in order to apply it in the next step on its correspondent set of the data */
    let filters: {
      thread: any;
      post: any;
      impact: any;
      train: any;
      item: any;
    } = {
      thread: this.app.filterList.getFunction("thread"),
      post: this.app.filterList.getFunction("post"),
      impact: this.app.filterList.getFunction("impact"),
      train: this.app.filterList.getFunction("train"),
      item: this.app.filterList.getFunction("internalItem"),
    };

    let filtersApplied = 0;

    let omfNumbers: string[] = [];
    /** apply filter function if the filters contain filter on the thread same for the posts/impacts/profile - favorite */
    if (threadFilterExists) {
      let threadResult = this.app.thread.threadsCompressed
        .filter((t) => filters.thread(t))
        .map((t) => t.omfNumber) as [];

      if (threadResult != null) {
        omfNumbers = [...omfNumbers, ...threadResult];
      } else {
        return [];
      }
      filtersApplied++;
    }

    if (openCasesFilterExists) {
      /** select closure status based on the customer */
      let closureStatus =
        this.app.customers.expectCurrent === Customer.DB
          ? "120"
          : "Geschlossen";
      /** apply filter */
      let openCaseResult = this.app.thread.threadsCompressed
        .filter((t: Partial<Thread>) => t.omfStatus !== closureStatus)
        .map((t) => t.omfNumber) as any[];
      omfNumbers = [...omfNumbers, ...openCaseResult];
      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((omfNumber: string) =>
          openCaseResult.includes(omfNumber)
        );
        omfNumbers = this.findDuplicates(omfNumbers);
      }
      filtersApplied++;
    }

    if (closedCasesFilterExists) {
      /** select closure status based on the customer */
      let closureStatus =
        this.app.customers.expectCurrent === Customer.DB
          ? "120"
          : "Geschlossen";
      /** apply filter */
      let closedCaseResult = this.app.thread.threadsCompressed
        .filter((t: Partial<Thread>) => t.omfStatus === closureStatus)
        .map((t) => t.omfNumber) as any[];
      omfNumbers = [...omfNumbers, ...closedCaseResult];
      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((omfNumber: string) =>
          closedCaseResult.includes(omfNumber)
        );
        omfNumbers = this.findDuplicates(omfNumbers);
      }
      filtersApplied++;
    }

    if (deputyFilterExists) {
      /** select all cases where the user logged in is set as deputy */
      let result = await this.hasToGiveFeedBack();
      omfNumbers = [...omfNumbers, ...result];
      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((omfNumber: string) =>
          result.includes(omfNumber)
        );
        omfNumbers = this.findDuplicates(omfNumbers);
      }
      filtersApplied++;
    }

    if (personalFilterExists) {
      /** select all cases where the user is responsible */
      let result = await this.hasToGiveFeedBack();
      omfNumbers = [...omfNumbers, ...result];
      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((omfNumber: string) =>
          result.includes(omfNumber)
        );
        omfNumbers = this.findDuplicates(omfNumbers);
      }
      filtersApplied++;
    }

    if (myResponsiblityFilterExists) {
      /** select all the cases where the current user is either responsible either deputy */
      let result = this.getAllTheCasesIamResponsibleFor(
        "myResponsiblityFilterExists"
      );
      omfNumbers = [...omfNumbers, ...result];
      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((omfNumber: string) =>
          result.includes(omfNumber)
        );
        omfNumbers = this.findDuplicates(omfNumbers);
      }
      filtersApplied++;
    }

    if (favFilterExists) {
      /** list of favorite cases */
      let favoriteListClient =
        this.app.profile.ownProfile.favorite[this.app.customers.expectCurrent];
      let favoriteListOmp = this.app.profile.ownProfile.favorite[Customer.OMP];
      let favResult = this.app.thread.threadsCompressed
        .filter(
          (t) =>
            (favoriteListOmp && favoriteListOmp.includes(t._id)) ||
            (favoriteListClient && favoriteListClient.includes(t._id))
        )
        .map((t: Partial<Thread>) => t.omfNumber) as any[];

      omfNumbers = [...omfNumbers, ...favResult];

      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((omfNumber) =>
          favResult.includes(omfNumber)
        );
        omfNumbers = this.findDuplicates(omfNumbers);
      }
      filtersApplied++;
    }

    if (postFilterExists) {
      this.app.spinner.showSpinner();
      this.app.post.posts = (await getDocsByType(
        this.app.customers.expectCurrent,
        "post"
      )) as Post[];
      let postResult = this.app.post.posts
        .filter((p) => filters.post(p))
        .map((p) => p.omfNumber) as [];
      let uniquePosts = new Set<string>([...postResult]);
      omfNumbers = [...omfNumbers, ...Array.from(uniquePosts)];

      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((e, i, a) => a.indexOf(e) !== i);
      }
      filtersApplied++;
    }

    if (impactFilterExists) {
      /** current field set on the filtering is impact type it has to return the omfNumber of the case containing that impact */
      this.app.spinner.showSpinner();
      let impactResult = this.app.impacts.impactsCompressed
        .filter((i) => {
          return filters.impact(i);
        })
        .map((i) => i.omfNumber) as [];
      let uniqueImpacts = new Set<string>([...impactResult]);
      omfNumbers = [...omfNumbers, ...Array.from(uniqueImpacts)];

      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((e, i, a) => a.indexOf(e) !== i);
      }
      filtersApplied++;
    }

    if (trainFilterExists) {
      this.app.spinner.showSpinner();
      const trainNames = this.app.train.trainsCompressed
        .filter((t) => {
          return filters.train(t);
        })
        .map((t) => t.trainName);
      const threadResult = this.app.thread.threadsCompressed
        .filter((thread) => {
          if (thread.vehicleNames != null) {
            const vehicleNames = thread.vehicleNames;
            const uniqueTrains = vehicleNames.filter((x) =>
              trainNames.includes(x)
            );
            if (uniqueTrains.length > 0) {
              return thread;
            }
          }
        })
        .map((i) => i.omfNumber) as [];

      const uniqueThreads = new Set<string>([...threadResult]);
      omfNumbers = [...omfNumbers, ...Array.from(uniqueThreads)];

      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((e, i, a) => a.indexOf(e) !== i);
      }
      filtersApplied++;
    }

    if (itemFilterExists) {
      this.app.spinner.showSpinner();
      let itemResult = this.app.internalItems.allItems
        .filter((i) => {
          return filters.item(i);
        })
        .map((i) => i.omfNumber) as [];
      let uniqueItems = new Set<string>([...itemResult]);
      omfNumbers = [...omfNumbers, ...Array.from(uniqueItems)];

      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((e, i, a) => a.indexOf(e) !== i);
      }
      filtersApplied++;
    }

    if (offerFilterExists) {
      let offerPostResult = this.app.thread.threadsCompressed
        .filter((t: Partial<Thread>) => t.typeOfPost === OFFER)
        .map((t) => t.omfNumber) as any[];
      omfNumbers = [...omfNumbers, ...offerPostResult];
      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((omfNumber: string) =>
          offerPostResult.includes(omfNumber)
        );
        omfNumbers = this.findDuplicates(omfNumbers);
      }
      filtersApplied++;
    }

    if (seekFilterExists) {
      let seekPostResult = this.app.thread.threadsCompressed
        .filter((t: Partial<Thread>) => t.typeOfPost === SEEK)
        .map((t) => t.omfNumber) as any[];
      omfNumbers = [...omfNumbers, ...seekPostResult];
      if (filtersApplied > 0) {
        omfNumbers = omfNumbers.filter((omfNumber: string) =>
          seekPostResult.includes(omfNumber)
        );
        omfNumbers = this.findDuplicates(omfNumbers);
      }
      filtersApplied++;
    }

    if (
      !threadFilterExists &&
      !favFilterExists &&
      !postFilterExists &&
      !impactFilterExists &&
      !openCasesFilterExists &&
      !closedCasesFilterExists &&
      !deputyFilterExists &&
      !personalFilterExists &&
      !trainFilterExists &&
      !itemFilterExists &&
      !myResponsiblityFilterExists &&
      !offerFilterExists &&
      !seekFilterExists
    ) {
      /** if none of the filters set return default */
      omfNumbers = this.app.thread.threadsCompressed.map(
        (t) => t.omfNumber
      ) as [];
    }

    /** result is the set of the omfNumbers returned by the filter function that needs to be taken out from the threadsCommpressed */
    /** the result can be the set of omfNumbers of impacts/posts/threads => which have to be translated to a thread type document */
    let result = this.app.thread.threadsCompressed.filter(
      (t: Partial<Thread>) => t.omfNumber && omfNumbers.includes(t.omfNumber)
    );
    this.app.thread.ids = result.map((t: Partial<Thread>) => t._id);
    this.app.table.sortDocs(result);
    this.app.spinner.hideSpinner();
    return result;
  }

  async getResponsiblesSpecialFilter() {
    /** new functionality */
    /** this.app.filterByPage stores all the current selected filters */
    /** the following checks are done to distinguish the type of filters */
    let vResponsibleFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField != null &&
          f.filterLabelField.includes("vehicleResponsible")
      ) !== -1;

    let responsiblePersonFilterExists =
      this.app.state.filterByPage.findIndex(
        (f: FilterPage) =>
          f != null &&
          f.filterLabelField != null &&
          f.filterLabelField.includes("responsibles.responsibles")
      ) !== -1;

    /** get filter function for each type of filtering-type in order to apply it in the next step on its correspondent set of the data */
    let filters: {
      responsible: any;
    } = {
      responsible: this.app.filterList.getFunction("vehicleResponsible"),
    };

    let filtersApplied = 0;

    let vehicleNames: string[] = [];
    /** apply filter function if the filters contains 'vehicleResponsible' */
    if (vResponsibleFilterExists) {
      let vResult: string[] = this.app.vehicleResponsible.vehicles
        .filter((t) => filters.responsible(t))
        .map((t) => t.vehicleName) as [];

      if (vResult != null) {
        vehicleNames = [...vehicleNames, ...vResult];
      } else {
        return [];
      }
      if (filtersApplied > 0) {
        vehicleNames = vehicleNames.filter((vName: string) =>
          vResult.includes(vName)
        );
        vehicleNames = this.findDuplicates(vehicleNames);
      }
      filtersApplied++;
    }

    if (responsiblePersonFilterExists) {
      let values: string[] = [];
      this.app.state.filterByPage.forEach((item) => {
        values = [...values, ...Array.from(item.values as string[])];
      });
      let personFilterResult: string[] = [];
      values.forEach((value) => {
        personFilterResult = [
          ...personFilterResult,
          ...this.app.vehicleResponsible.vehicles
            .filter(
              (v: any) =>
                v.responsibles && Object.values(v.responsibles).includes(value)
            )
            .map((vehicle: VehicleResponsible) => vehicle.vehicleName),
        ];
      });
      vehicleNames = [...vehicleNames, ...personFilterResult];
      if (filtersApplied > 0) {
        vehicleNames = vehicleNames.filter((vName: string) =>
          personFilterResult.includes(vName)
        );
        vehicleNames = this.findDuplicates(vehicleNames);
      }
      filtersApplied++;
    }

    if (!vResponsibleFilterExists && !responsiblePersonFilterExists) {
      /** if none of the filters set return default */
      vehicleNames = this.app.vehicleResponsible.vehicles.map(
        (v: VehicleResponsible) => v.vehicleName
      ) as [];
    }

    let result = this.app.vehicleResponsible.vehicles.filter(
      (v: Partial<VehicleResponsible>) =>
        v.vehicleName && vehicleNames.includes(v.vehicleName)
    );
    // this.app.table.sortDocs(result);
    this.app.spinner.hideSpinner();
    return result;
  }

  removeFilterValue(field: string | null, value: any) {
    let index = this.app.state.filterByPage.findIndex(
      (filterItem) =>
        filterItem.filterLabelField === field && filterItem.values.has(value)
    );
    this.app.state.filterByPage.forEach((filterItem) => {
      if (filterItem.values.has(value)) {
        filterItem.values.delete(value);
        if (field != null) {
          this.app.typeAheadBasic.model[field] = "";
        }
      }
    });
    if (index !== -1) {
      this.app.state.filterByPage.splice(index, 1);
    }
    if (field === "thread.user_id" || field === "thread.fav") {
      this.addQuickFilter("showAllCases");
    }

    this.app.paginator.focus = 0;
    this.app.paginator.pageSize = this.app.state.pageSize;
    this.app.model = {};

    this.getFilterResult();
  }

  addQuickFilter(filter: string) {
    // Reset the pagination if there is a new quick filter selected
    if (filter !== this.quickFilterSelected) {
      this.app.paginator.focus = 0;
    }

    this.quickFilterSelected = filter;
    this.app.filterList.field = null;
    this.app.filterList.open = false;
    switch (filter) {
      case "fav":
        this.app.filterList.clearQuickFilters("thread.user_id");
        this.app.filterList.clearQuickFilters("openCases");
        this.app.filterList.clearQuickFilters("closedCases");
        this.app.filterList.clearQuickFilters("deputy");
        this.app.filterList.clearQuickFilters("personal");
        this.app.filterList.clearQuickFilters("responsible");
        this.app.filterList.clearQuickFilters("offer");
        this.app.filterList.clearQuickFilters("seek");
        if (
          this.app.state.filterByPage.findIndex(
            (f) => f.filterLabelField === "thread.fav"
          ) === -1
        ) {
          this.app.filterList.createFilter(
            this.app.fieldId.thread.fav,
            (filter) => {
              filter.values.add("true");
            }
          );
        }
        break;
      case "own":
        this.app.filterList.clearQuickFilters("thread.fav");
        this.app.filterList.clearQuickFilters("openCases");
        this.app.filterList.clearQuickFilters("closedCases");
        this.app.filterList.clearQuickFilters("deputy");
        this.app.filterList.clearQuickFilters("personal");
        this.app.filterList.clearQuickFilters("responsible");
        this.app.filterList.clearQuickFilters("offer");
        this.app.filterList.clearQuickFilters("seek");
        if (this.app.customers.expectCurrent !== Customer.DB) {
          if (
            this.app.state.filterByPage.findIndex(
              (f) => f.filterLabelField === "thread.user_id"
            ) === -1
          ) {
            this.app.filterList.createFilter(
              this.app.fieldId.thread.user_id,
              (filter) => {
                filter.values.add(this.app.user);
              }
            );
          }
        } else {
          if (
            this.app.state.filterByPage.findIndex(
              (f) => f.filterLabelField === "thread.dinCodeRespName"
            ) === -1
          ) {
            this.app.filterList.createFilter(
              this.app.fieldId.thread.dinCodeRespName,
              (filter) => {
                filter.values.add(this.app.user);
              }
            );
          }
        }
        break;
      case "openCases":
        this.app.filterList.clearQuickFilters("thread.fav");
        this.app.filterList.clearQuickFilters("thread.user_id");
        this.app.filterList.clearQuickFilters("deputy");
        this.app.filterList.clearQuickFilters("personal");
        this.app.filterList.clearQuickFilters("closedCases");
        this.app.filterList.clearQuickFilters("responsible");
        this.app.filterList.clearQuickFilters("offer");
        this.app.filterList.clearQuickFilters("seek");
        this.app.filterList.createFilter("openCases", (filter) =>
          filter.values.add("openCases")
        );
        break;
      case "closedCases":
        this.app.filterList.clearQuickFilters("thread.fav");
        this.app.filterList.clearQuickFilters("thread.user_id");
        this.app.filterList.clearQuickFilters("deputy");
        this.app.filterList.clearQuickFilters("personal");
        this.app.filterList.clearQuickFilters("openCases");
        this.app.filterList.clearQuickFilters("responsible");
        this.app.filterList.clearQuickFilters("offer");
        this.app.filterList.clearQuickFilters("seek");
        this.app.filterList.createFilter("closedCases", (filter) =>
          filter.values.add("closedCases")
        );
        break;
      case "personal":
        this.app.filterList.clearQuickFilters("thread.fav");
        this.app.filterList.clearQuickFilters("thread.user_id");
        this.app.filterList.clearQuickFilters("openCases");
        this.app.filterList.clearQuickFilters("closedCases");
        this.app.filterList.clearQuickFilters("deputy");
        this.app.filterList.clearQuickFilters("responsible");
        this.app.filterList.clearQuickFilters("offer");
        this.app.filterList.clearQuickFilters("seek");
        this.app.filterList.createFilter("personal", (filter) =>
          filter.values.add("personal")
        );
        break;
      case "deputy":
        this.app.filterList.clearQuickFilters("thread.fav");
        this.app.filterList.clearQuickFilters("thread.user_id");
        this.app.filterList.clearQuickFilters("openCases");
        this.app.filterList.clearQuickFilters("closedCases");
        this.app.filterList.clearQuickFilters("personal");
        this.app.filterList.clearQuickFilters("responsible");
        this.app.filterList.clearQuickFilters("offer");
        this.app.filterList.clearQuickFilters("seek");
        this.app.filterList.createFilter("deputy", (filter) =>
          filter.values.add("deputy")
        );
        break;
      case "responsible":
        this.app.filterList.clearQuickFilters("thread.fav");
        this.app.filterList.clearQuickFilters("thread.user_id");
        this.app.filterList.clearQuickFilters("openCases");
        this.app.filterList.clearQuickFilters("closedCases");
        this.app.filterList.clearQuickFilters("personal");
        this.app.filterList.clearQuickFilters("offer");
        this.app.filterList.clearQuickFilters("seek");
        this.app.filterList.createFilter("responsible", (filter) =>
          filter.values.add("responsible")
        );
        break;
      case "offer":
        this.app.filterList.clearQuickFilters("thread.fav");
        this.app.filterList.clearQuickFilters("thread.user_id");
        this.app.filterList.clearQuickFilters("openCases");
        this.app.filterList.clearQuickFilters("closedCases");
        this.app.filterList.clearQuickFilters("personal");
        this.app.filterList.clearQuickFilters("responsible");
        this.app.filterList.clearQuickFilters("seek");
        this.app.filterList.createFilter("offer", (filter) =>
          filter.values.add("offer")
        );
        break;
      case "seek":
        this.app.filterList.clearQuickFilters("thread.fav");
        this.app.filterList.clearQuickFilters("thread.user_id");
        this.app.filterList.clearQuickFilters("openCases");
        this.app.filterList.clearQuickFilters("closedCases");
        this.app.filterList.clearQuickFilters("personal");
        this.app.filterList.clearQuickFilters("responsible");
        this.app.filterList.clearQuickFilters("offer");
        this.app.filterList.createFilter("seek", (filter) =>
          filter.values.add("seek")
        );
        break;
      case "showAllCases":
        this.app.filterList.clearQuickFilters("thread.fav");
        this.app.filterList.clearQuickFilters("thread.user_id");
        this.app.filterList.clearQuickFilters("openCases");
        this.app.filterList.clearQuickFilters("closedCases");
        this.app.filterList.clearQuickFilters("deputy");
        this.app.filterList.clearQuickFilters("personal");
        this.app.filterList.clearQuickFilters("responsible");
        this.app.filterList.clearQuickFilters("offer");
        this.app.filterList.clearQuickFilters("seek");
        this.app.thread.ids = this.app.thread.threadsCompressed.map(
          (doc) => doc._id
        );
        break;
    }

    this.app.paginator.pageSize = this.app.state.pageSize;
  }

  getQuickFilterText(filter: string): string {
    if (filter === "") {
      return this.app.text.home.showAllCases;
    }
    switch (filter) {
      case "fav":
        return this.app.text.home.fav;
      case "own":
        return this.app.text.home.own;
      case "showAllCases":
        return this.app.text.home.showAllCases;
      case "openCases":
        return this.app.text.home.openCases;
      case "closedCases":
        return this.app.text.home.closedCases;
      case "personal":
        return this.app.text.home.personal;
      case "deputy":
        return this.app.text.home.deputy;
      case "responsible":
        return this.app.text.home.responsible;
      case "offer":
        return this.app.text.home.offer;
      case "seek":
        return this.app.text.home.seek;
      case "myTasks":
        return this.app.text.post.myTasks;
      case "myOpenTasks":
        return this.app.text.post.myOpenTasks;
      case "createdTasks":
        return this.app.text.post.createdTasks;
      case "createdOpenTasks":
        return this.app.text.post.createdOpenTasks;
      case "allTasks":
        return this.app.text.post.allTasks;
      case "allOpenTasks":
        return this.app.text.post.allOpenTasks;
      default:
        return this.app.text.home.showAllCases;
    }
  }

  /** find the duplicates omfNumbers */
  private findDuplicates(array: any[]): any[] {
    let sortedArray = array.slice().sort();
    let results = [];
    for (let i = 0; i < sortedArray.length - 1; i++) {
      if (sortedArray[i + 1] === sortedArray[i]) {
        results.push(sortedArray[i]);
      }
    }
    return results;
  }

  async hasToGiveFeedBack() {
    let omfNumbers = new Set<string>();

    switch (this.quickFilterSelected) {
      case "personal":
        // Get list of tasks for which the logged in user is responsible for
        if (
          this.app.state.user != null &&
          this.app.customers.expectCurrent === Customer.DB
        ) {
          const myTasks = await getTaskByTab(
            this.app.customers.expectCurrent,
            this.app.state.user,
            MY_TASKS
          );
          this.app.tasks.tasks = myTasks.filter(
            (t) => t.taskCompleted === false || t.taskCompleted == null
          );
        }

        this.app.tasks.tasks.forEach((task: Task) => {
          if (task.omfNumber != null) {
            omfNumbers.add(task.omfNumber);
          }
        });
        console.log("cases where I am responsible", omfNumbers);
        return Array.from(omfNumbers);
      case "deputy":
        let uncompletedTasksDeputy: Task[] = [];
        if (
          this.app.state.user != null &&
          this.app.customers.expectCurrent === Customer.DB
        ) {
          uncompletedTasksDeputy = await getUncompletedTasksByDeputy(
            this.app.customers.expectCurrent,
            this.app.state.user
          );
        }

        uncompletedTasksDeputy.forEach((task: Task) => {
          if (task.omfNumber != null) {
            omfNumbers.add(task.omfNumber);
          }
        });
        console.log("cases where I am a deputy", omfNumbers);
        return Array.from(omfNumbers);
      default:
        return this.app.thread.threadsCompressed.map(
          (t) => t.omfNumber
        ) as string[];
    }
  }

  /** bring the list of cases where I am set as responsible/deputy */
  getAllTheCasesIamResponsibleFor(filterType?: string) {
    let omfNumbers = new Set<string>();
    this.app.impacts.impactsCompressed.filter((doc: any) => {
      if (doc.value) {
        Object.keys(doc.value).forEach((key: string) => {
          if (filterType === "myResponsiblityFilterExists") {
            /** except vertreters from my responsibility filter */
            if (
              !key.includes("Vertreter") &&
              doc.value[key] === this.app.user
            ) {
              omfNumbers.add(doc.omfNumber);
            }
          } else {
            if (doc.value[key] === this.app.user) {
              omfNumbers.add(doc.omfNumber);
            }
          }
        });
      }
    });
    return Array.from(omfNumbers);
  }

  isQuickFilterField(field: string) {
    switch (field) {
      case "thread.fav":
      case "thread.user_id":
      case "openCases":
      case "closedCases":
      case "deputy":
      case "personal":
      case "responsible":
      case "offer":
      case "seek":
        return true;
      default:
        return false;
    }
  }
}
