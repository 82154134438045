<app-modal
  #deleteCommentModal
  [modalMessage]="app.text.post.deleteCommentQuestion"
  [modalTitle]="app.text.post.modalTitle"
  (ok)="app.OCComment.deleteComment(app.OCComment.selectedComment)"
></app-modal>
<app-modal
  #discardChangesModal
  [modalMessage]="app.text.app.discardChanges"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="app.OCComment.discardChanges()"
>
</app-modal>

<div class="row">
  <div class="col-2"></div>
  <div class="col-8 px-0 mb-2">
    <textarea
      type="text"
      placeholder="Share your thoughts on this post"
      class="input form-control"
      [(ngModel)]="app.OCComment.commentDoc['message']"
    ></textarea>
  </div>
  <div class="col-2 p-1 align-self-center">
    <button
      type="button"
      (click)="app.OCComment.saveComment()"
      class="btn btn-sm btn-post"
      [disabled]="
        app.OCComment.commentDoc['message'] === '' || !app.OCPost.isReadMode
      "
    >
      <i class="fa-solid fa-paper-plane-top"></i>
      {{ app.text.ocPost.comment }}
    </button>
  </div>
</div>
<div>
  <div class="py-1" *ngFor="let comment of app.OCComment.comments">
    <div class="row">
      <div class="col-2 d-flex justify-content-center">
        <img
          *ngIf="comment.avatar != ''"
          class="profile-image"
          target="_blank"
          [src]="app.file.getUrl(comment.avatar)"
        />
        <img
          *ngIf="comment.avatar === ''"
          class="profile-image"
          target="_blank"
          [src]="app.url.profile.defaultImage"
        />
      </div>
      <div class="col-8 px-0 input readMode">
        <div class="d-flex">
          <span class="text">
            <!-- <b>{{ comment.userFirstName }}&nbsp;{{ comment.userLastName }}</b> -->
            <b>{{ comment.user_id }}</b>
          </span>
          <div class="controls-text ms-auto">
            {{ app.OCHome.getTimeInfo(comment.create_time) }}
          </div>
        </div>
        <div
          *ngIf="
            app.OCPost.isReadMode ||
            comment._id !== app.OCComment.selectedComment._id
          "
          class="text"
        >
          <slot>
            <div
              class="fake-textarea"
              [innerHtml]="app.field.highlightLinks(comment.message)"
            ></div>
          </slot>
        </div>
        <textarea
          *ngIf="!app.OCPost.isReadMode && comment._id === app.OCHome.editedId"
          [(ngModel)]="app.OCComment.cleanComment.message"
          type="text"
          [disabled]="app.OCPost.isReadMode"
          class="input textarea-edit"
        ></textarea>
        <app-attachments
          *ngIf="app.unlockedId === null"
          [id]="comment._id"
          type="ocComment"
          [editPermission]="app.OCHome.hasEditPermission(comment)"
          [files]="comment._attachments"
          [filesDetails]="comment.attachmentHistory"
        ></app-attachments>
      </div>
      <div class="col-2">
        <div
          *ngIf="!app.OCPost.isReadMode && comment._id === app.OCHome.editedId"
        >
          <button
            class="btn btn-sm btn-control"
            (click)="app.OCComment.updateComment()"
            [title]="app.text.thread.save"
            [disabled]="app.OCComment.modelHasChanges()"
          >
            <i class="fa fa-check save-icon"></i>
          </button>
          <button
            class="btn btn-sm btn-control"
            (click)="discardChangesModal.open()"
          >
            <i class="fas fa-times close-icon"></i>
          </button>
        </div>
        <div *ngIf="app.OCPost.isReadMode" class="d-flex flex-column">
          <div *ngIf="app.OCHome.hasEditPermission(comment)">
            <button
              class="btn btn-control"
              (click)="
                app.OCComment.selectedComment = comment;
                app.OCHome.editDoc(comment._id);
                app.OCHome.createCleanModel(comment);
                app.OCPost.isReadMode = false
              "
            >
              <i class="fa fa-edit"></i>
            </button>
            <button
              class="btn btn-control"
              [title]="app.text.post.delete"
              (click)="
                app.OCComment.selectedComment = comment;
                deleteCommentModal.open()
              "
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
          <div *ngIf="app.OCHome.hasEditPermission(comment)" class="ms-1">
            <app-attachments-controls
              [id]="comment._id"
              [isColored]="false"
              type="ocComment"
            ></app-attachments-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
