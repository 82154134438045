import { Component, OnInit } from "@angular/core";
import { Customer } from "../../../../shared/types/customers";
import { Profile } from "../../../../shared/models/profile";
import { User } from "../../../../shared/models/user";
import { getApp } from "../app";

@Component({
  selector: "app-user-profile-list",
  templateUrl: "./user-profile-list.component.html",
  styleUrls: ["./user-profile-list.component.scss"],
})
export class UserProfileListComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });
  profiles: Profile[] = [];
  columns: string[] = [];
  usersNames: string[] = [];
  // filterOpen: boolean = false;

  selectedFilters: { columnName: string; value: string }[] = [];

  constructor() {}

  async ngOnInit() {
    this.app.type = "personProfile";
    await this.app.users.getListOfUsersCompressed();
    if (this.app.users.usersCompressed !== undefined) {
      this.usersNames = this.app.users.usersCompressed.map(
        (user: Partial<User>) => (user.name != null ? user.name : "")
      );
      if (this.usersNames != null) {
        await this.app.profile.getProfiles(this.usersNames);
      }
    }

    this.getSortOption();
  }

  getSortOption() {
    if (this.app.customers.expectCurrent === Customer.OMP) {
      this.app.table.sortField = "user._id";
      this.app.table.sortDirection = 1;
    }
  }

  getData(profile: any, column: string) {
    if (profile.sections != null) {
      let field = column.split(".")[1];

      if (column.includes("organizationProfile")) {
        return profile.sections.org[field];
      }

      if (column.includes("personProfile")) {
        return profile.sections.pers[field];
      }
      if (column === "user.email") {
        return profile.sections.pers.Email;
      }
    }

    if (column === "user.name") {
      return profile._id;
    }
    return profile[column];
  }
}
