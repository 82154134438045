<app-oc-post-modal
  #ocPostModal
  [title]="app.text.history.manufacturerHistory"
></app-oc-post-modal>

<div class="container-fluid">
  <!-- First line -->
  <div class="row">
    <!-- Search Input for filter -->
    <div class="col-md-10 d-flex flex-row p-2">
      <!-- New post -->
      <div>
        <button
          class="btn btn-sm btn-default-orange mt-1"
          (click)="ocPostModal.open()"
        >
          <i class="fa fa-plus"></i>&nbsp;&nbsp; {{ app.text.ocPost.newPost }}
        </button>
      </div>
      <div *ngFor="let type of filterModes">
        <button
          class="btn btn-sm badge m-1"
          [ngClass]="{ 'badge-active': selectedType === type }"
          type="button"
          (click)="setType(type)"
        >
          <i *ngIf="selectedType !== type" class="fa-light fa-circle-dot"></i>
          <i *ngIf="selectedType === type" class="fa-regular fa-circle-dot"></i>
          {{ getTextDisplay(type) }}
        </button>
      </div>
      <div class="search flex-grow-1">
        <input
          type="text"
          *ngIf="!isFilterSelected"
          class="input input-search mt-1"
          placeholder="Search for posts..."
          [(ngModel)]="searchWords[selectedType]"
          (keydown.enter)="
            app.OCHome.filterOCPosts(selectedType, searchWords[selectedType]);
            isSelected(selectedType)
          "
        />
        <button
          class="btn btn-sm search-btn me-0"
          *ngIf="isFilterSelected"
          type="button"
          (click)="clearFilter()"
        >
          {{ selectedValue }}
          <i class="fa-light fa-times clear-icon"></i>
        </button>
        <button
          class="btn search-btn mt-1"
          (click)="
            app.OCHome.filterOCPosts(selectedType, searchWords[selectedType]);
            isSelected(selectedType)
          "
        >
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
    <!-- Quick filters -->
    <div class="col-md-2 d-flex flex-row-reverse p-2">
      <!-- Sowing Options -->
      <!-- <div class="dropdown">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="quickFilters"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="icon-center me-1">
            <i class="fas fa-eye menu-icons"></i>
          </div>
          <span>&nbsp;Show&nbsp;</span>
          <span class="text-danger">
            {{ app.OCHome.getPostFilterText(app.OCHome.filterMode) }}
          </span>
        </button>
        <div class="dropdown-menu" aria-labelledby="quickFiltersDropdown">
          <a
            class="dropdown-item pointer"
            *ngFor="let filter of app.OCHome.postFilters"
            (click)="scrollToLastSeen(filter); app.post.isFilterSet = true"
            >{{ app.OCHome.getPostFilterText(filter) }}</a
          >
        </div>
      </div> -->
      <!-- Sort Options -->
      <div class="dropdown">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="sortOptions"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="icon-center me-1">
            <i class="fas fa-sort-alt menu-icons"></i>
          </div>
          <span>&nbsp;{{ app.text.home.sort }}&nbsp;</span>
          <span
            class="text-danger"
            *ngIf="app.OCHome.sortingOrder === 'descending'"
            >&nbsp;{{ app.text.post.newest }}&nbsp;</span
          >
          <span
            class="text-danger"
            *ngIf="app.OCHome.sortingOrder === 'ascending'"
            >&nbsp;{{ app.text.post.oldest }}&nbsp;</span
          >
        </button>
        <div class="dropdown-menu" aria-labelledby="sortOptions">
          <div class="dropdown-item pointer">
            <i class="fas fa-sort-amount-down text-danger"></i>
            <a
              (click)="
                app.OCHome.sortingOrder = 'descending';
                app.OCHome.sortPosts(app.OCHome.posts, 'descending')
              "
            >
              {{ app.text.ocPost.sortToOldest }}</a
            >
          </div>
          <div class="dropdown-item pointer">
            <i class="fas fa-sort-amount-up text-danger"></i>
            <a
              (click)="
                app.OCHome.sortingOrder = 'ascending';
                app.OCHome.sortPosts(app.OCHome.posts, 'ascending')
              "
            >
              {{ app.text.ocPost.sortToNewest }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Second line -->
  <div class="row mb-2 bottom-line"></div>

  <!-- 3th line - Content -->
  <div class="row">
    <!-- First Section (2/5) -->
    <div class="col-md-4 col-sm-12">
      <!-- <div class="card mb-2 p-3" [ngClass]="{ next: !show }">
        <span (click)="toggleNewPost()" class="text-center post-header">
          <b>{{ app.text.ocPost.postQuestion }}</b>
          <i
            *ngIf="!show"
            class="fa-solid fa-chevron-down icon-orange p-1 m-1"
          ></i>
          <i
            *ngIf="show"
            class="fa-solid fa-chevron-up icon-orange p-1 m-1"
          ></i>
        </span>
        <div *ngIf="show" class="d-flex">
          <div class="d-flex flex-column flex-grow-1">
            <input
              [(ngModel)]="app.OCHome.postDoc['title']"
              type="text"
              placeholder="Write something meaningful for the topic you want to write about"
              class="input"
            />

            <textarea
              [(ngModel)]="app.OCHome.postDoc['message']"
              placeholder="The related post should address issues concerning obsolescence, the supply chain, or a specific Manufacturer Part Number (MPN). If applicable, please feel free to include the MPN in the content as well."
              class="input"
            ></textarea>
          </div>
          <div class="d-flex flex-column flex-shrink-1">
            <div class="dropdown mb-auto">
              <button
                class="btn btn-sm dropdown-toggle btn-tag ms-1"
                type="button"
                id="dropdownTags"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                [ngStyle]="
                  app.OCPostItem.getTagColor(
                    app.fieldId.ocPost.tagCategory,
                    app.OCHome.postDoc['tag']
                  )
                "
              >
                {{
                  app.field.getOptionText(
                    app.fieldId.ocPost.tagCategory,
                    app.OCHome.tag
                  )
                }}
              </button>
              <ul class="dropdown-menu">
                <li *ngFor="let tag of app.OCHome.tags">
                  <a
                    class="dropdown-item pointer"
                    (click)="app.OCHome.updateTag(tag)"
                    >{{
                      app.field.getOptionText(
                        app.fieldId.ocPost.tagCategory,
                        tag
                      )
                    }}</a
                  >
                </li>
              </ul>
            </div>
            <button
              type="button"
              (click)="app.OCPost.savePost()"
              class="btn btn-sm btn-post text-center ms-1"
              [disabled]="
                app.OCHome.postDoc['title'] === '' ||
                app.OCHome.postDoc['message'] === ''
              "
            >
              <i class="fa-solid fa-paper-plane-top"></i>
              {{ app.text.ocPost.post }}
            </button>
          </div>
        </div>
      </div> -->
      <div
        [ngClass]="{ card: app.OCHome.posts.length == 0 }"
        class="scroll mb-2"
      >
        <div
          class="no-results my-5 col-12"
          *ngIf="app.OCHome.posts.length == 0"
        >
          {{ app.text.home.noResults }}
        </div>
        <div
          *ngFor="let post of app.OCHome.posts"
          [id]="
            post._id === app.profile.ownProfile.lastSeen
              ? 'lastSeenMessage'
              : null
          "
        >
          <app-oc-post-item [post]="post"></app-oc-post-item>
        </div>
      </div>
    </div>
    <!-- Second Section (2/5) -->
    <div class="col-md-8 col-sm-12">
      <div [ngClass]="{ card: app.OCPost.selectedPost == null }">
        <div
          class="no-results my-5 col-12"
          *ngIf="app.OCPost.selectedPost == null"
        >
          {{ app.text.home.noResults }}
        </div>
        <div *ngIf="app.OCPost.selectedPost != null">
          <app-oc-post></app-oc-post>
        </div>
      </div>
    </div>
    <!-- Third Section (1/5) Attachments and Post Image -->
  </div>
</div>
