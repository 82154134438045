import { Component, OnDestroy, OnInit } from "@angular/core";
import { getApp } from "../app";
import { ActivatedRoute } from "@angular/router";
import { User } from "../../../../shared/models/user";
import { Subscription } from "rxjs";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit, OnDestroy {
  userName: string = "";
  app = getApp((app) => {
    this.app = app;
  });
  ids: string[] = [];
  showEmailsBox = false;
  private _routeParamsSubscription: Subscription = new Subscription();

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this._routeParamsSubscription = this.route.params.subscribe(
      async (params) => {
        this.app.users.userName = params.user;
        this.app.users.prepareUser();
        if (params.user) {
          /** used for navigation between users */
          await this.app.users.getUsersWithRoles();
          this.ids = this.app.users.userRoles.map((user: any) => user.name);
        }
      }
    );

    this.showEmailPreferencesBox();
  }

  discardChanges() {
    this.app.unlockedId = null;
    this.app.users.currentUser = this.app.users.currentUser;
    this.app.users.getUserModelClean();
    this.app.userSettings.currentUserEmailPreferences = [
      ...this.app.userSettings.cleanUserEmailPreferences,
    ];
    if (this.app.users.isNew) {
      this.app.users.currentUser = {} as User;
      this.app.routing.navigateUsers();
    }
    this.app.field.inputSubject.next(true);
  }

  showSaveButton() {
    if (
      this.app.field.getInvalidFields("user", this.app.users.currentUser)
        .length < 1 &&
      this.app.users.modelHasChanges
    ) {
      return true;
    }
    if (
      this.app.field.getInvalidFields("user", this.app.users.currentUser)
        .length < 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  showEmailPreferencesBox() {
    if (
      !this.app.users.isNew &&
      !this.app.auth.isTeam &&
      this.app.customers.expectCurrent !== Customer.OC
    ) {
      this.showEmailsBox = true;
    }
  }

  /** Fix for logging out while on user page, reset the fields for the login page */
  ngOnDestroy(): void {
    const fieldId = this.app.fieldId.user.name;
    this.app.field.resetAllRequiredFieldSettings("user");
    this.app.field.getFieldSettings(fieldId).mutable = true;
    this.app.field.getFieldSettings("user.name").required = true;
    this.app.field.getFieldSettings("user.password").required = true;

    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
  }
}
