import { typeCategories } from "../typeCategories";
import { getDinCodeOptions } from "../models/dinCodes";
import {
  ComponentSettings,
  empty,
  FieldTypes,
  FilterTypes,
} from "../settings/settings";
import { creditsFields } from "./credits.settings";
import { checkBoxOptions } from "./manufacturer.settings";
import { obsolescenceStatusOptions } from "./part.settings";
import { getCountries } from "../utils/countryCodes.utils";

export const omfStatuses: any = {
  "Notiz-Fall": { text: { de: "Notiz-Fall", en: "Note", nl: "Notitie" } },
  vorerfasst: { text: { de: "Vorerfasst", en: "Draft", nl: "Ontwerp" } },
  "In Bearbeitung": {
    text: { de: "In Bearbeitung", en: "In Progress", nl: "In uitvoering" },
  },
  Geschlossen: { text: { de: "Geschlossen", en: "Closed", nl: "Gesloten" } },
};

export const endOfProduction = {
  type: FieldTypes.date,
  text: {
    de: "Einstellung der Produktion (EOP) / Effective Date",
    en: "End of Production (EOP) / Effective Date",
    nl: "Eind van de productie (EOP) / Ingangsdatum",
  },
  required: false,
};
export const productCategory = {
  type: FieldTypes.options,
  xml: "itemCategory",
  text: {
    de: "Kategorie der Einheit",
    en: "Category of the Item",
    nl: "Item productcategorie",
  },
  tooltip: { de: "", en: "", nl: "" },
  required: false,
  maxLength: 16,
  options: {
    ACEL: {
      text: {
        de: "Aktive Elektronik",
        en: "Active Electronic",
        nl: "Actieve Elektronica",
      },
    },
    DACE: {
      text: {
        de: "Daten / Zertifikat",
        en: "Data / Certificates",
        nl: "Data / Certificaten",
      },
    },
    SERV: {
      text: {
        de: "Dienstleistung",
        en: "Services",
        nl: "Diensten",
      },
    },
    DOCU: {
      text: {
        de: "Dokumentation",
        en: "Documentation",
        nl: "Documentatie",
      },
    },
    ELME: {
      text: {
        de: "Elektromechanik",
        en: "Electro Mechanical",
        nl: "Elektromechanica",
      },
    },
    FLUI: {
      text: {
        de: "Fluid",
        en: "Fluids",
        nl: "Vloeistoffen",
      },
    },
    AUXM: {
      text: {
        de: "Hilfsmaterial",
        en: "Auxiliary Materials",
        nl: "Hulpmateriaal",
      },
    },
    HYDR: {
      text: {
        de: "Hydraulik",
        en: "Hydraulic",
        nl: "Hydraulisch",
      },
    },
    MECH: {
      text: {
        de: "Mechanik",
        en: "Mechanical",
        nl: "Mechanisch",
      },
    },
    MULT: {
      text: {
        de: "Mehrere Kategorien",
        en: "Multiple Categories",
        nl: "Verschillende Categorieën",
      },
    },
    PAEL: {
      text: {
        de: "Passive Elektrik / Elektronik",
        en: "Passive Electrical / Electronic",
        nl: "Passieve Elektrisch / Elektronisch",
      },
    },
    PNEU: {
      text: {
        de: "Pneumatik",
        en: "Pneumatic",
        nl: "Pneumatisch",
      },
    },
    RAWM: {
      text: {
        de: "Rohmaterial",
        en: "Raw Material",
        nl: "Basismateriaal",
      },
    },
    SWFW: {
      text: {
        de: "Software / Firmware",
        en: "Software / Firmware",
        nl: "Software / Firmware",
      },
    },
    OTHR: {
      text: {
        de: "Sonstige",
        en: "Other",
        nl: "Overige",
      },
    },
    CCBL: {
      text: {
        de: "Steckverbinder / Kabel",
        en: "Connectors / Cables",
        nl: "Stekkers / Kabels",
      },
    },
    ASSY: {
      text: {
        de: "Zusammenbau",
        en: "Assembly",
        nl: "Assemblage",
      },
    },
  },
};

export const partNumber = {
  type: FieldTypes.typeahead,
  xml: "artNumber",
  text: {
    de: "Kundenteilenummer (KTN)",
    en: "Customer Part Number (CPN)",
    nl: "Artikel Nummer (CPN)",
  },
  unique: false,
  filter: FilterTypes.startsWith,
  tooltip: { de: "", en: "", nl: "" },
};

export const field = {
  _id: {
    type: FieldTypes.text,
    text: {
      de: "Database ID",
      en: "Database ID",
    },
  },
  dueDate: {
    type: FieldTypes.date,
    text: {
      de: "Fälligkeitsdatum",
      en: "Due Date",
      nl: "Vervaldatum",
    },
  },
  title: {
    type: FieldTypes.text,
    xml: "pcnTitle",
    text: {
      de: "Titel",
      en: "Title",
      nl: "Titel",
    },
    required: true,
    maxLength: 256,
    rows: 1,
    tooltip: {
      de: "",
      en: "",
      nl: "",
    },
  },
  company: {
    type: FieldTypes.value,
    text: {
      de: "Firmenbezeichnung",
      en: "Company Name",
      nl: "Leverancier",
    },
    required: false,
    tooltip: {
      de: "",
      en: "",
      nl: "",
    },
  },
  ...creditsFields,
  creator_class: {
    type: FieldTypes.options,
    xml: "pcnType",
    required: false,
    text: {
      de: "Art der Mitteilung",
      en: "Type of Notice",
      nl: "Type Notificatie",
    },
    options: {
      PCN: {
        text: {
          de: "Änderungsmitteilung (PCN)",
          en: "Product Change Notice (PCN)",
          nl: "Productwijziging (PCN)",
        },
      },
      PDN: {
        text: {
          de: "Abkündigungsmitteilung (PDN)",
          en: "Product Discontinuance Notice (PDN)",
          nl: "Productbeëindiging (PDN)",
        },
      },
    },
    tooltip: {
      de: "",
      en: "",
      nl: "",
    },
  },
  dinCodeRespNameShort: {
    type: FieldTypes.value,
    text: {
      de: "Verantwortlicher Namenskürzel",
      en: "Responsible Name short",
      nl: "Verantwoordelijke kort",
    },
  },
  identificationMethod: {
    xml: "pcnChangeIdentificationMethod",
    type: FieldTypes.text,
    text: {
      de: "Identifikation der geänderten Einheit(en) / des Produkts",
      en: "Identification of the changed item(s) / product",
      nl: "Identificatie van de gewijzigde items",
    },
    maxLength: 2048,
    tooltip: { de: "", en: "", nl: "" },
  },
  omfStatus: {
    type: FieldTypes.options,
    text: {
      de: "Status",
      en: "Status",
      nl: "Status",
    },
    options: omfStatuses,
    // mutable: false,
    required: true,
  },
  priority: {
    type: FieldTypes.options,
    text: {
      de: "Priorität",
      en: "Priority",
      nl: "Prioriteit",
    },
    options: {
      low: { text: { de: "Low", en: "Low", nl: "Laag" } },
      medium: { text: { de: "Medium", en: "Medium", nl: "Medium" } },
      high: { text: { de: "High", en: "High", nl: "Hoog" } },
    },
    value: "medium",
  },
  omfStatusDescription: {
    type: FieldTypes.options,
    text: {
      de: "Status Beschreibung",
      en: "Status Description",
      nl: "Status Beschrijving",
    },
    options: {},
  },
  omfStatusResponsible: {
    type: FieldTypes.options,
    text: {
      de: "Status Verantwortlicher",
      en: "Status Responsible",
      nl: "Status Verantwoordelijke",
    },
    options: {},
  },
  caseSource: {
    type: FieldTypes.radio,
    text: {
      de: "Ursprung des Falles",
      en: "Source of the Case",
      nl: "Oorsprong Issue",
    },
    options: {
      intern: { text: { de: "Intern", en: "Internal", nl: "Intern" } },
      extern: { text: { de: "Extern", en: "External", nl: "Extern" } },
    },
  },
  omfNumber: {
    type: FieldTypes.value,
    text: {
      de: "Fall-Nummer",
      en: "Case Number",
      nl: "Issue Nummer",
    },
    required: true,
    mutable: false,
    format: {
      text: "OMF",
      values: { number: { at: 3, digits: 4, fixed: false } },
      regex: "^(OMF)([0-9]{4})$", //default omf number format
      textInFilter: "OMF",
    },
  },
  omfShortDescr: {
    type: FieldTypes.text,
    xml: "itemMfrName",
    text: {
      de: "Bezeichnung der Einheit (Name/Kurztext)",
      en: "Designation of the Item (Name/short text)",
      nl: "Item omschrijving",
    },
    maxLength: 128,
    required: false,
    tooltip: { de: "", en: "", nl: "" },
  },
  crtNumber: {
    type: FieldTypes.typeahead,
    xml: "itemMfrNumber",
    text: {
      de: "Herstellerteilenummer (HTN)",
      en: "Manufacturer Part Number (MPN)",
      nl: "Fabrikant Artikel Nummer (MPN)",
    },
    unique: false,
    maxLength: 128,
    required: false,
    filter: FilterTypes.startsWith,
    tooltip: { de: "", en: "", nl: "" },
  },
  artNumber: partNumber,
  productCategory,
  productCategoryActiveElectronics: {
    type: FieldTypes.options,
    text: {
      de: "Unterkategorie Aktive Elektronik",
      en: "Product Category Active Electronics",
      nl: "Productcategorie Actieve Elektronica",
    },
    options: {
      diode: { text: { de: "Diode", en: "Diode", nl: "Diode" } },
      microcircuit: {
        text: { de: "Microcircuit", en: "Microcircuit", nl: "Microcircuit" },
      },
      transistor: {
        text: { de: "Transistor", en: "Transistor", nl: "Transistor" },
      },
    },
  },
  productCategoryPassiveElectronics: {
    type: FieldTypes.options,
    text: {
      de: "Unterkategorie Passive Elektronik",
      en: "Product Category Passive Electronics",
      nl: "Productcategorie Passieve Elektronica",
    },
    options: {
      capacitor: {
        text: { de: "Capacitor", en: "Capacitor", nl: "Condensator" },
      },
      connector: {
        text: { de: "Connector", en: "Connector", nl: "Connector" },
      },
      crystal: { text: { de: "Crystal", en: "Crystal", nl: "Kristal" } },
      filter: { text: { de: "Filter", en: "Filter", nl: "Filter" } },
      fuse: { text: { de: "Fuse", en: "Fuse", nl: "Zekering" } },
      inductor: { text: { de: "Inductor", en: "Indicator", nl: "Indicator" } },
      "optoelectronic-device": {
        text: {
          de: "OptoElectronic Device",
          en: "OptoElectronic Device",
          nl: "OptoElektronisch apparaat",
        },
      },
      relay: { text: { de: "Relay", en: "Relay", nl: "Relais" } },
      resistor: { text: { de: "Resistor", en: "Resistor", nl: "Weerstand" } },
      switch: { text: { de: "Switch", en: "Switch", nl: "Schakelaar" } },
      "terminal-block": {
        text: { de: "Terminal Block", en: "Terminal Block", nl: "Klemmenblok" },
      },
      transformer: {
        text: { de: "Transformer", en: "Transformer", nl: "Transformator" },
      },
    },
  },
  itemSubData: {
    xml: "itemSubData",
    type: FieldTypes.text,
    text: {
      de: "Weitere Merkmale",
      en: "Other identifying measures",
      nl: "Andere identificerende kenmerken",
    },
    maxLength: 2048,
    tooltip: { de: "", en: "", nl: "" },
  },
  werk: {
    type: FieldTypes.text,
    text: {
      de: "Werk",
      en: "Plant",
      nl: "Productielocatie",
    },
    maxLength: 128,
  },
  dinCode: {
    type: FieldTypes.options,
    text: {
      de: "DIN Code (15380-2)",
      en: "DIN Code (15380-2)",
      nl: "DIN Code (15380-2)",
    },
    options: getDinCodeOptions((code) => code),
    required: true,
    tooltip: { de: "", en: "", nl: "" },
  },
  dinText: {
    type: FieldTypes.options,
    text: {
      de: "DIN Text (15380-2)",
      en: "DIN Text (15380-2)",
      nl: "DIN Tekst (15380-2)",
    },
    options: getDinCodeOptions((code, text) => text),
    required: true,
    tooltip: { de: "", en: "", nl: "" },
  },
  changeClasses: {
    type: FieldTypes.options,
    required: true,
    xml: "itemChangeType",
    text: {
      de: "Kategorie der Art der PCN/PDN",
      en: "Category of the type of PCN/PDN",
      nl: "Categorie van wijziging",
    },
    multiple: true,
    sort: true,
    options: typeCategories,
    tooltip: { de: "", en: "", nl: "" },
  },
  creator: {
    type: FieldTypes.value,
    xml: "pcnCompany",
    text: {
      de: "Hersteller",
      en: "Manufacturer",
      nl: "Fabrikant",
    },
    maxLength: 128,
    required: false,
    tooltip: { de: "", en: "", nl: "" },
  },
  supplier: {
    type: FieldTypes.value,
    text: {
      de: "Lieferant",
      en: "Supplier",
      nl: "Leverancier",
    },
  },
  supplierEmail: {
    type: FieldTypes.value,
    text: {
      de: "Lieferant Email",
      en: "Supplier Email",
      nl: "Leverancier Email",
    },
  },
  mfrhighlevel: {
    type: FieldTypes.value,
    xml: "pcnMfrName",
    text: {
      de: "Herstellerkurzbezeichnung",
      en: "Manufacturer Short Name",
      nl: "Fabrikant kort",
    },
    maxLength: 32,
    required: false,
    tooltip: { de: "", en: "", nl: "" },
  },
  crtContact: {
    type: FieldTypes.value,
    xml: "pcnEmail",
    text: {
      de: "E-Mail Adresse",
      en: "E-mail address",
      nl: "Email",
    },
    maxLength: 256,
    required: false,
    tooltip: { de: "", en: "", nl: "" },
    regex: "",
  },
  crtStreet: {
    type: FieldTypes.value,
    required: false,
    text: { de: "Straße", en: "Street", nl: "Straat" },
    tooltip: { de: "", en: "", nl: "" },
  },
  crtPostCode: {
    type: FieldTypes.value,
    required: false,
    text: { de: "Postleitzahl", en: "Postcode", nl: "Postcode" },
    tooltip: { de: "", en: "", nl: "" },
  },
  crtCity: {
    type: FieldTypes.value,
    required: false,
    text: { de: "Stadt", en: "City", nl: "Stad" },
    tooltip: { de: "", en: "", nl: "" },
  },
  crtState: {
    type: FieldTypes.value,
    text: { de: "Bundesland", en: "State", nl: "Provincie / Staat" },
    tooltip: { de: "", en: "", nl: "" },
  },
  crtCountry: {
    type: FieldTypes.value,
    required: false,
    options: getCountries(),
    text: { de: "Ländercode", en: "Country", nl: "Land" },
    tooltip: { de: "", en: "", nl: "" },
  },
  issueDate: {
    xml: "issueDate",
    type: FieldTypes.date,
    text: {
      de: "PCN",
      en: "PCN",
      nl: "PCN",
    },
  },
  pcnID: {
    type: FieldTypes.value,
    xml: "pcnNumber",
    text: {
      de: "Identifikationsschlüssel (ID) der PCN/PDN",
      en: "Identification Key (ID) of the PCN/PDN",
      nl: "Identificatiesleutel (ID)",
    },
    maxLength: 128,
    required: false,
    tooltip: { de: "", en: "", nl: "" },
  },
  pcnRevision: {
    type: FieldTypes.value,
    xml: "pcnRevision",
    text: {
      de: "Revisionsnummer",
      en: "Revision number",
      nl: "Revisie nummer",
    },
    maxLength: 16,
    tooltip: { de: "", en: "", nl: "" },
  },
  typeIdNum: {
    type: FieldTypes.value,
    xml: "itemRev",
    text: {
      de: "Revisionsstand",
      en: "Revision status",
      nl: "Revisie status",
    },
    maxLength: 32,
    tooltip: { de: "", en: "", nl: "" },
  },
  drawNumber: {
    type: FieldTypes.value,
    xml: "itemMfrTypeIdent",
    text: {
      de: "Typenbez. oder Zeichnung",
      en: "Type designation or drawing",
      nl: "Typeaanduiding",
    },
    maxLength: 128,
    tooltip: { de: "", en: "", nl: "" },
  },
  designType: {
    type: FieldTypes.value,
    text: {
      de: "Bauart/Typ",
      en: "Design/Type",
    },
    maxLength: 128,
  },
  oDate: {
    xml: "issueDate",
    type: FieldTypes.date,
    text: {
      de: "Datum der Veröffentlichung",
      en: "Date of publication",
      nl: "Publicatiedatum",
    },
    required: false,
  },
  itemSOP: {
    xml: "itemSOP",
    type: FieldTypes.date,
    text: {
      de: "Beginn der Herstellung (SOP)",
      en: "Start of Production (SOP)",
      nl: "Start van de productie (SOP)",
    },
  },
  itemEOP: {
    xml: "itemEOPeffDate",
    ...endOfProduction,
  },
  itemEOS: {
    xml: "itemEOS",
    type: FieldTypes.date,
    text: {
      de: "Einstellung des Vertriebs (EOS)",
      en: "End Of Sale (EOS)",
      nl: "Einde van de verkoop (EOS)",
    },
  },
  itemLTD: {
    xml: "itemLTD",
    type: FieldTypes.date,
    text: {
      de: "Letzte mögliche Lieferung (LTD)",
      en: "Last Time Delivery (LTD)",
      nl: "Laatste levering (LTD)",
    },
  },
  itemEOSR: {
    xml: "itemEOSR",
    type: FieldTypes.date,
    text: {
      de: "Einstellung von Service (EOSR)",
      en: "End Of Service & Repair (EOSR)",
      nl: "Einde van de service & reparatie (EOSR)",
    },
  },
  replmanfItemnumber: {
    xml: "replmanfItemnumber",
    type: FieldTypes.text,
    text: {
      de: "Vorgeschlagene Ersatz-Herstellerteilenummer (HTN)",
      en: "Manufacturer Part Number (MPN) of the proposed Replacement",
      nl: "Voorgestelde vervanging (MPN)",
    },
    maxLength: 128,
    tooltip: { de: "", en: "", nl: "" },
  },
  descr: {
    xml: "pcnChangeDetail",
    type: FieldTypes.text,
    text: {
      de: "Technische Beschreibung",
      en: "Technical Description",
      nl: "Beschrijving",
    },
    maxLength: 4096,
    required: false,
    tooltip: { de: "", en: "", nl: "" },
  },
  safetyEvaluation: {
    type: FieldTypes.checkbox,
    text: {
      de: "Sicherheitsbewertung abgeschlossen",
      en: "Safety evaluation finished",
      nl: "Veiligheidsbeheerproces afgerond",
    },
    checkboxLabels: checkBoxOptions,
  },
  entryDate: {
    type: FieldTypes.date,
    text: {
      de: "Eingangsdatum",
      en: "Entry Date",
      nl: "Ingangsdatum",
    },
  },
  entryCheckbox: {
    type: FieldTypes.checkbox,
    text: {
      de: "KLÜ-Eintrag",
      en: "CRT-Entry",
      nl: "CRT-Entry",
    },
    checkboxLabels: checkBoxOptions,
  },
  businessArea: {
    type: FieldTypes.options,
    text: {
      de: "Geschäftsfelder",
      en: "Business Area",
      nl: "Bedrijfssectoren",
    },
    multiple: true,
    options: {
      REGIO: {
        text: {
          de: "Regio",
          en: "Regio",
          nl: "Regio",
        },
      },
      CARGO: {
        text: {
          de: "Cargo",
          en: "Cargo",
          nl: "Cargo",
        },
      },
      FERNVERKEHR: {
        text: {
          de: "Fernverkehr",
          en: "Long-distance",
          nl: "Intercity",
        },
      },
      FZ: {
        text: {
          de: "FZI",
          en: "FZI",
          nl: "FZI",
        },
      },
      SONSTIGE: {
        text: {
          de: "Sonstige",
          en: "Other",
          nl: "Overig",
        },
      },
    },
  },
  deputy: {
    type: FieldTypes.value,
    text: {
      de: "Stellvertreter",
      en: "Deputy",
      nl: "Assistent",
    },
  },
  deputyDepartment: {
    type: FieldTypes.value,
    text: {
      de: "Abteilung",
      en: "Department",
      nl: "Afdeling",
    },
  },
  deputyEmail: {
    type: FieldTypes.value,
    text: {
      de: "Email",
      en: "Email",
      nl: "Email",
    },
  },
  dlzDays: {
    type: FieldTypes.value,
    text: {
      de: "DLZ Tage",
      en: "DLT Days",
      nl: "Procestijd",
    },
  },
  dinCodeRespName: {
    type: FieldTypes.value,
    text: { de: "Bearbeiter OM", en: "Responsible", nl: "OM contactpersoon" },
  },
  dinCodeRespEmail: {
    type: FieldTypes.value,
    text: {
      de: "Email Bearbeiter OM",
      en: "Responsible Email",
      nl: "Email OM aanspreekpunt",
    },
  },
  closingDate: {
    text: {
      de: "Geplantes Abschlussdatum",
      en: "Closing Date",
      nl: "Plandatum",
    },
    type: FieldTypes.date,
  },
  complexity: {
    type: FieldTypes.value,
    text: { de: "Komplexität", en: "Complexity", nl: "Complexiteit" },
  },
  actualEffort: {
    type: FieldTypes.value,
    text: {
      de: "Tatsächlicher Aufwand",
      en: "Actual effort",
      nl: "Werkelijke uitgaven",
    },
  },
  componentResponsible: {
    type: FieldTypes.value,
    text: {
      de: "Komponentenverantwortlicher",
      en: "Component responsible",
    },
  },
  componentResponsibleEmail: {
    type: FieldTypes.value,
    text: {
      de: "Komponentenverantwortlicher Email",
      en: "Component responsible Email",
    },
  },
  componentResponsibleSigned: {
    type: FieldTypes.value,
    text: {
      de: "Komponentenverantwortlicher Signed",
      en: "Component responsible Signed",
    },
  },
  partNumber: {
    type: FieldTypes.value,
    xml: "artNumber",
    text: {
      de: "Kundenteilenummer (KTN)",
      en: "Customer Part Number (CPN)",
      nl: "Artikel Nummer (CPN)",
    },
    filter: FilterTypes.startsWith,
  },
  sapNumber: {
    type: FieldTypes.value,
    text: {
      de: "SAP-Anfragenummer",
      en: "SAP Inquiry Number",
    },
  },
  dueDateClass: {
    type: FieldTypes.options,
    text: { de: "Due Date Klasse", en: "Due Date Class" },
    options: {
      "low-risk": {
        text: { de: "Low Risk", en: "Low Risk", nl: "Laag Risico" },
      },
      "medium-risk": {
        text: { de: "Medium Risk", en: "Medium Risk", nl: "Medium Risico" },
      },
      "high-risk": {
        text: { de: "High Risk", en: "High Risk", nl: "Hoog Risico" },
      },
      "very-high-risk": {
        text: {
          de: "Very High Risk",
          en: "Very High Risk",
          nl: "Zeer hoog risico",
        },
      },
    },
  },
  intDescr: {
    text: {
      de: "Interne Beschreibung",
      en: "Internal Description",
      nl: "Interne Beschrijving",
    },
    type: FieldTypes.text,
  },
  indicatorName: {
    type: FieldTypes.options,
    text: { de: "Melder", en: "Indicator", nl: "Melder" },
  },
  reporterName: {
    type: FieldTypes.value,
    text: {
      de: "Melder",
      en: "Reporter",
      nl: "Melder",
    },
  },
  indicatorNameShort: {
    type: FieldTypes.value,
    text: {
      de: "Melder Namenskürzel",
      en: "Indicator Short Name",
      nl: "Melder Naam Kort",
    },
  },
  indicatorDep: {
    type: FieldTypes.value,
    text: {
      de: "Melder Abteilung",
      en: "Indicator Department",
      nl: "Melder afdeling",
    },
  },
  indicatorEmail: {
    type: FieldTypes.value,
    text: { de: "Melder Email", en: "Indicator Email", nl: "Melder Email" },
  },
  indicatorTelephone: {
    type: FieldTypes.value,
    text: {
      de: "Melder Telefon",
      en: "Responsible telephone",
      nl: "Melder telefoonnummer",
    },
  },
  dinCodeRespDep: {
    type: FieldTypes.value,
    text: {
      de: "Bereich (Verantwortlicher)",
      en: "Department (Responsible)",
      nl: "Verantwoordelijke afdeling",
    },
  },
  dinCodeRespTelephone: {
    type: FieldTypes.value,
    text: {
      de: "Verantwortlicher Telefon",
      en: "Responsible Telephone",
      nl: "Verantwoordelijke Telefoonnummer",
    },
  },
  dinCodeRisk: {
    type: FieldTypes.options,
    text: { de: "Risiko-Klasse", en: "Risk", nl: "Risico" },
    options: {
      IO: { text: { de: "IO", en: "IO", nl: "IO" } },
      SK: { text: { de: "SK", en: "SK", nl: "SK" } },
      BK: { text: { de: "BK", en: "BK", nl: "BK" } },
      UE: { text: { de: "UE", en: "UE", nl: "UE" } },
    },
    multiple: true,
  },
  relevantToSecurity: {
    type: FieldTypes.checkbox,
    text: {
      de: "Sicherheitskritisch",
      en: "Relevant to security",
      nl: "Veiligheidskritisch",
    },
    checkboxLabels: checkBoxOptions,
  },
  vehicleCnt: {
    type: FieldTypes.value,
    text: {
      de: "Summe betroffener Komponenten",
      en: "Components involved",
      nl: "Aantal Items",
    },
  },
  vehicleNames: {
    type: FieldTypes.value,
    text: {
      de: "Vehicle Names",
      en: "Vehicle Names",
      nl: "Vehicle Names",
    },
  },
  forecast: {
    text: {
      de: "Anzahl der noch benötigten Items",
      en: "Total number of items required",
      nl: "Totaal benodigde Items",
    },
    type: FieldTypes.value,
  },
  buyer: {
    type: FieldTypes.value,
    text: { de: "Operativer Einkäufer", en: "Buyer", nl: "Inkoper" },
  },
  materialPlaner: {
    type: FieldTypes.value,
    text: { de: "Materialdisponent", en: "Material Planer", nl: "Planner" },
  },
  averageCons: {
    type: FieldTypes.number,
    text: {
      de: "Ø Verbrauch pro Jahr", //& bemptyv;
      en: "Ø Consumption per year",
      nl: "Ø Verbruik per jaar",
    },
  },
  actualStock: {
    type: FieldTypes.number,
    text: {
      de: "Aktueller Lagerbestand",
      en: "Actual stock",
      nl: "Voorraad totaal",
    },
  },
  actualStockBr: {
    type: FieldTypes.number,
    text: {
      de: "Aktueller Lagerbestand in Br",
      en: "Actual Stock in Br",
    },
  },
  actualStockFi: {
    type: FieldTypes.number,
    text: {
      de: "Aktueller Lagerbestand in Fi",
      en: "Actual Stock in Fi",
    },
  },
  actualStockGru: {
    type: FieldTypes.number,
    text: {
      de: "Aktueller Lagerbestand in Gru",
      en: "Actual Stock in Gru",
    },
  },
  actualStockSee: {
    type: FieldTypes.number,
    text: {
      de: "Aktueller Lagerbestand in See",
      en: "Actual Stock in See",
    },
  },
  omfCommodityId: {
    type: FieldTypes.value,
    text: {
      de: "Warengruppe Nr.",
      en: "Commodity Nr.",
      nl: "Productgroep nr.",
    },
  },
  omfCommodityDescr: {
    type: FieldTypes.value,
    text: {
      de: "Warengruppenbeschreibung",
      en: "Commodity Description",
      nl: "Beschrijving van de productgroep",
    },
  },
  omfCommodityRespName: {
    type: FieldTypes.value,
    text: {
      de: "Warengruppe Verantwortlicher",
      en: "Commodity Department Responsible",
      nl: "Productgroep Verantwoordelijke",
    },
  },
  omfCraft: {
    type: FieldTypes.value,
    text: { de: "Gewerk", en: "Craft", nl: "Werkplaats" },
  },
  omfCraftRespName: {
    type: FieldTypes.value,
    text: {
      de: "Verantwortlicher Gewerk",
      en: "Craft Responsible",
      nl: "Werkplaats verantwoordelijke",
    },
  },
  customers: {
    type: FieldTypes.value,
    text: { de: "Betroffene Kunden", en: "Customers", nl: "Getroffen klanten" },
  },
  externalIds: {
    type: FieldTypes.value,
    text: { de: "Externe IDs", en: "External IDs", nl: "External IDs" },
  },
  originOfNotification: {
    type: FieldTypes.options,
    text: {
      de: "Ursprung der Meldung",
      en: "Origin of notification",
      nl: "Oorsprong van het bericht",
    },
    options: {
      internal: {
        text: {
          de: "Intern ohne OMF Formular",
          en: "internal without OMF form",
          nl: "Intern zonder OMF-formulier",
        },
      },
      internalForm: {
        text: {
          de: "Intern mit OMF Formular",
          en: "internal with OMF form",
          nl: "Intern met OMF-formulier",
        },
      },
      external: {
        text: {
          de: "Lieferant ohne OMF Formular",
          en: "external without OMF form",
          nl: "Leverancier zonder OMF-formulier",
        },
      },
      externalForm: {
        text: {
          de: "Lieferant mit OMF Formular",
          en: "external with OMF form",
          nl: "Leverancier met OMF-formulier",
        },
      },
      pcngenerator: {
        text: { de: "PCN Generator", en: "PCN Generator", nl: "PCN Generator" },
      },
    },
  },
  engineerName: {
    type: FieldTypes.value,
    text: {
      de: "Ingenieur Komponente",
      en: "Engineer name",
      nl: "Engineer naam",
    },
  },
  engineerDep: {
    type: FieldTypes.value,
    text: {
      de: "Bereich (Ingenieur)",
      en: "Department (Engineer)",
      nl: "Engineer afdeling",
    },
  },
  engineerEmail: {
    type: FieldTypes.value,
    text: { de: "Ingenieur Email", en: "Engineer email", nl: "Engineer email" },
    required: false,
  },
  taxonomy: {
    type: FieldTypes.value,
    text: { de: "Einordnung", en: "Taxonomy", nl: "Classificatie" },
    options: {
      subway: {
        text: {
          de: "U-Bahn",
          en: "Subway",
          nl: "Metro",
        },
      },
      tram: {
        text: {
          de: "Straßenban",
          en: "Tram",
          nl: "Tram",
        },
      },
      bus: {
        text: {
          de: "Bus",
          en: "Bus",
          nl: "Bus",
        },
      },
    },
  },
  workflowResponsible: {
    type: FieldTypes.value,
    text: {
      de: "Verantwortlicher Workflow",
      en: "Workflow responsible",
      nl: "Verantwoordelijke workflow",
    },
  },
  workflowResponsibleEmail: {
    type: FieldTypes.value,
    text: {
      de: "Verantwortlicher Workflow (Email)",
      en: "Workflow responsible (Email)",
      nl: "Verantwoordelijke workflow (e-mail)",
    },
    options: {},
  },
  averageConsStochastic: {
    type: FieldTypes.number,
    text: {
      de: "Ø Bedarf pro Jahr",
      en: "Ø Demand per year",
      nl: "Ø Vraag per jaar",
    },
  },
  totalConsumation: {
    type: FieldTypes.value,
    text: {
      de: "Voraussichtlicher Verbrauch pro Jahr (wird berechnet)",
      en: "Expected consumption per year (will be calculated)",
      nl: "Verwacht verbruik per jaar (zal worden berekend)",
    },
    mutable: false,
  },
  stockRangeCons: {
    type: FieldTypes.value,
    text: {
      de: "Lagerreichweite (Verbrauch) (wird berechnet)",
      en: "Stock range (consumption) (calculated)",
      nl: "Opslagbereik (verbruik) (wordt berekend)",
    },
    mutable: false,
    unit: {
      de: "Tage",
      en: "days",
      nl: "days",
    },
  },
  stockRangeRequired: {
    type: FieldTypes.value,
    text: {
      de: "Lagerreichweite (Bedärfe) (wird berechnet)",
      en: "Stock range (requirements) (calculated)",
      nl: "Opslagbereik (eisen) (wordt berekend)",
    },
    mutable: false,
    unit: {
      de: "Tage",
      en: "days",
      nl: "days",
    },
  },
  stockRangeCR: {
    type: FieldTypes.value,
    text: {
      de: "Lagerreichweite (Verbrauch & Bedärfe) (wird berechnet)",
      en: "Stock range (consumption & requirements) (calculated)",
      nl: "Opslagbereik (verbruik & behoeften) (wordt berekend)",
    },
    mutable: false,
    unit: {
      de: "Tage",
      en: "days",
      nl: "days",
    },
  },
  actualStockDate: {
    type: FieldTypes.date,
    text: {
      de: "Lagerbestand zum",
      en: "Actual Stock Date",
      nl: "Actuele voorraad datum",
    },
  },
  closingDateAfterCons: {
    type: FieldTypes.value,
    text: {
      de: "Abschlussdatum nach Verbrauch (wird berechnet)",
      en: "Closing date after consumption (calculated)",
      nl: "Sluitingsdatum volgens verbruik (wordt berekend)",
    },
    mutable: false,
  },
  closingDateRequired: {
    type: FieldTypes.value,
    text: {
      de: "Abschlussdatum nach Bedarf (wird berechnet)",
      en: "Closing date as required (calculated)",
      nl: "Sluitingsdatum zoals vereist (zal worden berekend)",
    },
    mutable: false,
  },
  closingDateCR: {
    type: FieldTypes.value,
    text: {
      de: "Abschlussdatum (nach Bedarf und Verbrauch) (berechnet)",
      en: "Closing date (requirements & consumption) (calculated)",
      nl: "Sluitingsdatum zoals vereist (zal worden berekend)",
    },
    mutable: false,
  },
  items: {
    type: FieldTypes.data,
    text: {
      de: "SAP Nummern",
      en: "SAP Numbers",
      nl: "SAP Numbers",
    },
  },
  fav: {
    type: FieldTypes.checkbox,
    text: {
      de: "Favorit",
      en: "Favorite",
      nl: "Favoriet",
    },
    options: checkBoxOptions,
  },
  seen: {
    type: FieldTypes.checkbox,
    text: {
      de: "Gelesen",
      en: "Seen",
      nl: "Gelezen",
    },
    checkboxLabels: checkBoxOptions,
  },
  vehicles: {
    type: FieldTypes.data,
    text: {
      de: "Fahrzeuge",
      en: "Vehicles",
      nl: "Voertuigen",
    },
  },
  last_update: {
    type: FieldTypes.number,
    text: {
      en: "Updated on",
      de: "Geändert am",
      nl: "Gewijzigd op",
    },
  },
  threadImage: {
    type: FieldTypes.value,
    text: {
      de: "Image Thread",
      en: "Image Thread",
      nl: "Image Thread",
    },
    search: false,
  },
  omApproach: {
    type: FieldTypes.options,
    text: {
      de: "OM-Ansatz",
      en: "OM-Approach",
    },
    options: {
      reactive: {
        text: {
          de: "Reaktiv",
          en: "Reactive",
          nl: "Reactieve",
        },
      },
      proactive: {
        text: {
          de: "Proaktiv",
          en: "Proactive",
          nl: "Proactieve",
        },
      },
    },
    search: false,
  },
  usedIn: {
    type: FieldTypes.options,
    text: {
      de: "Baureihe ",
      en: "Used In",
    },
    options: {
      ES64F4: {
        text: {
          de: "ES64F4",
          en: "ES64F4",
        },
      },
      ES64U2: {
        text: {
          de: "ES64U2",
          en: "ES64U2",
        },
      },
      X4E: {
        text: {
          de: "X4E",
          en: "X4E",
        },
      },
      BR_185: {
        text: {
          de: "BR 185",
          en: "BR 185",
        },
      },
      BR_484: {
        text: {
          de: "BR 484",
          en: "BR 484",
        },
      },
      BR_145: {
        text: {
          de: "BR 145",
          en: "BR 145",
        },
      },
      other: {
        text: {
          de: "Sonstige",
          en: "Other",
        },
      },
    },
    search: false,
    multiple: true,
  },
  commodityClass: {
    type: FieldTypes.options,
    text: {
      de: "Güterklasse",
      en: "Commodity Class",
    },
    options: {
      "0": { text: { de: "0-Unknown", en: "0-Unknown" } },
      "01": { text: { de: "1-De nul nul een", en: "1-De nul nul een" } },
      "1": { text: { de: "1-ME26 - ME26", en: "1-ME26 - ME26" } },
      "2": { text: { de: "2-U2 - ES64 U2", en: "2-U2 - ES64 U2" } },
      "3": {
        text: { de: "3-BS - Oil & lubricants", en: "3-BS - Oil & lubricants" },
      },
      "4": {
        text: {
          de: "4-UNI - General purpose material",
          en: "4-UNI - General purpose material",
        },
      },
      "5": {
        text: {
          de: "5-AU2 - Repairable ES64 U2",
          en: "5-AU2 - Repairable ES64 U2",
        },
      },
      "6": { text: { de: "6-P - ES64 P", en: "6-P - ES64 P" } },
      "7": { text: { de: "7-U2F4 - U2 and F4", en: "7-U2F4 - U2 and F4" } },
      "8": { text: { de: "8-VM - Consumables", en: "8-VM - Consumables" } },
      "9": {
        text: {
          de: "9-AU2F - Repairable U2 and F4",
          en: "9-AU2F - Repairable U2 and F4",
        },
      },
      "10": {
        text: {
          de: "10-AUNI - Repairable general purp",
          en: "10-AUNI - Repairable general purp",
        },
      },
      "11": { text: { de: "11-F4 - ES64 F4", en: "11-F4 - ES64 F4" } },
      "12": {
        text: {
          de: "12-AF4 - Repairable ES64 F4",
          en: "12-AF4 - Repairable ES64 F4",
        },
      },
      "14": {
        text: {
          de: "14-AP - Repairable ES64 P",
          en: "14-AP - Repairable ES64 P",
        },
      },
      "18": { text: { de: "18-WKZ - Tools", en: "18-WKZ - Tools" } },
      "19": {
        text: {
          de: "19-ALT - Old parts for repair",
          en: "19-ALT - Old parts for repair",
        },
      },
      "20": {
        text: {
          de: "20-EinmalArtikel - Once only part",
          en: "20-EinmalArtikel - Once only part",
        },
      },
      "21": {
        text: { de: "21-TEXT - Text parts", en: "21-TEXT - Text parts" },
      },
      "27": { text: { de: "27-185 - BR 185", en: "27-185 - BR 185" } },
      "29": {
        text: {
          de: "29-AU4 - Repairable ES64 U4",
          en: "29-AU4 - Repairable ES64 U4",
        },
      },
      "32": { text: { de: "32-145 - BR 145", en: "32-145 - BR 145" } },
      "33": {
        text: {
          de: "33-Repairable F4 and ER20",
          en: "33-Repairable F4 and ER20",
        },
      },
      "34": {
        text: { de: "34-Repairable F4 and U4", en: "34-Repairable F4 and U4" },
      },
      "35": { text: { de: "35-U2 and U4", en: "35-U2 and U4" } },
      "36": {
        text: { de: "36-Repairable BR 185", en: "36-Repairable BR 185" },
      },
      "37": {
        text: {
          de: "37-Repairable U2 and ER20",
          en: "37-Repairable U2 and ER20",
        },
      },
      "38": { text: { de: "38-F4 and U4", en: "38-F4 and U4" } },
      "39": { text: { de: "39-U2 and ER20", en: "39-U2 and ER20" } },
      "40": {
        text: { de: "40-Repairable U2 and U4", en: "40-Repairable U2 and U4" },
      },
      "41": { text: { de: "41-BR 185 and F4", en: "41-BR 185 and F4" } },
      "42": {
        text: {
          de: "42-Repairable BR 185 and F4",
          en: "42-Repairable BR 185 and F4",
        },
      },
      "43": {
        text: {
          de: "43-U2F4185 - U2, F4 and BR 185",
          en: "43-U2F4185 - U2, F4 and BR 185",
        },
      },
      "44": {
        text: {
          de: "44-U2185 - U2 and BR 185",
          en: "44-U2185 - U2 and BR 185",
        },
      },
      "45": {
        text: {
          de: "45-AU4 BR185 - Repair U4 BR185",
          en: "45-AU4 BR185 - Repair U4 BR185",
        },
      },
      "46": { text: { de: "46-X4", en: "46-X4" } },
      "47": { text: { de: "47-Repairable X4", en: "47-Repairable X4" } },
      "48": { text: { de: "48-U2, F4, X4", en: "48-U2, F4, X4" } },
      "49": { text: { de: "49-re484 Repair", en: "49-re484 Repair" } },
      "50": {
        text: { de: "50-Repairable ER20, X4", en: "50-Repairable ER20, X4" },
      },
      "51": { text: { de: "51-U2, X4", en: "51-U2, X4" } },
      "52": { text: { de: "52-F4, X4", en: "52-F4, X4" } },
      "53": {
        text: { de: "53-Repairable F4, X4", en: "53-Repairable F4, X4" },
      },
      "54": {
        text: {
          de: "54-4U2U4185 - F4, U2, U4 Br. 18",
          en: "54-4U2U4185 - F4, U2, U4 Br. 18",
        },
      },
      "55": { text: { de: "55-BR185X4F4U2U4", en: "55-BR185X4F4U2U4" } },
      "56": {
        text: {
          de: "56-Repairable F4,Br.185, re484,X4",
          en: "56-Repairable ER20, X4",
        },
      },
      "57": {
        text: {
          de: "57-Repairable U2,Br.185",
          en: "57-Repairable F4,Br.185, re484,X4",
        },
      },
      "58": {
        text: {
          de: "58-Repairable U2,Br.185,F4,U4",
          en: "58-Repairable U2,Br.185,F4,U4",
        },
      },
      "59": { text: { de: "59-185, X4", en: "59-185, X4" } },
      "60": { text: { de: "60-X4, U2, U4", en: "60-X4, U2, U4" } },
      "61": {
        text: { de: "61-U4 - X4 repairable", en: "61-U4 - X4 repairable" },
      },
      "62": {
        text: {
          de: "62-Repairable U2, F4, X4",
          en: "62-Repairable U2, F4, X4",
        },
      },
      "63": { text: { de: "63-F4,U2,U4", en: "63-F4,U2,U4" } },
      "64": { text: { de: "64-185 and 484", en: "64-185 and 484" } },
      "65": { text: { de: "65-RE484", en: "65-RE484" } },
      "66": { text: { de: "66-F4 + ER20", en: "66-F4 + ER20" } },
      "67": {
        text: { de: "67-Technician Equpiment", en: "67-Technician Equpiment" },
      },
      "68": { text: { de: "68-F4, 185, X4E", en: "68-F4, 185, X4E" } },
      "69": { text: { de: "69-185.2", en: "69-185.2" } },
      "70": { text: { de: "70-F4, 185.1", en: "70-F4, 185.1" } },
      "71": {
        text: { de: "71-F4,Br.185, re484,X4", en: "71-F4,Br.185, re484,X4" },
      },
      FBD: { text: { de: "FBD-Show me", en: "FBD-Show me" } },
    },
    search: false,
  },
  statusRisk: {
    type: FieldTypes.options,
    text: {
      de: "Status Risiko",
      en: "Status Risk",
    },
    options: {
      low: { text: { de: "Low", en: "Low" } },
      medium: { text: { de: "Medium", en: "Medium" } },
      high: { text: { de: "High", en: "High" } },
    },
    search: false,
  },
  stakeholder: {
    type: FieldTypes.options,
    text: {
      de: "Beteiligte",
      en: "Stakeholder",
    },
    multiple: true,
    search: false,
  },
  repairable: {
    type: FieldTypes.checkbox,
    text: {
      de: "Reparierbare Einheit",
      en: "Repairable Item",
      nl: "Wisseldeel",
    },
    checkboxLabels: checkBoxOptions,
  },
  obsolescenceStatus: {
    type: FieldTypes.options,
    text: {
      de: "Aktueller Obsoleszenzstatus",
      en: "Current Obsolescence status",
      nl: "Actuele Obsolescence Status",
    },
    options: obsolescenceStatusOptions,
  },
  mtbf: {
    type: FieldTypes.number,
    text: {
      de: "MTBF (Stunden)",
      en: "MTBF (hours)",
    },
  },

  //ns contact data fields
  teamMemberName: {
    type: FieldTypes.options,
    text: {
      de: "Name des OM-Teammitglieds",
      en: "Name of OM Team Member",
      nl: "OM Contact",
    },
  },
  supplyManagerName: {
    type: FieldTypes.options,
    text: {
      de: "Supplychain-Manager",
      en: "Supplychain-Manager",
      nl: "Supplychain-Manager",
    },
  },
  buyerName: {
    type: FieldTypes.options,
    text: {
      de: "Einkäufer",
      en: "Buyer",
      nl: "Inkoper",
    },
  },
  plannerName: {
    type: FieldTypes.options,
    text: {
      de: "Planer",
      en: "Planner",
      nl: "Planner",
    },
  },
  sysEngineerName: {
    type: FieldTypes.options,
    text: {
      de: "Systemingenieur",
      en: "System Engineer",
      nl: "System Engineer",
    },
  },
  otherStakeholder: {
    type: FieldTypes.options,
    text: {
      de: "Weitere Stakeholder",
      en: "Other stakeholders",
      nl: "Overige stakeholders",
    },
    multiple: true,
  },
  otherStakeholderEmail: {
    type: FieldTypes.value,
    text: {
      de: "Emailadresse eines weiteren Stakeholders",
      en: "Email address of other stakeholder",
      nl: "E-mailadres overige stakeholders",
    },
  },
  itemType: {
    type: FieldTypes.options,
    text: {
      de: "Typ der Einheit",
      en: "Item Type",
      nl: "Artikeltype",
    },
    options: {
      SLIJT: { text: { de: "SLIJT", en: "SLIJT", nl: "SLIJT" } },
      WISSEL_INTERN_NEWBUY: {
        text: {
          de: "WISSEL INTERN NEWBUY",
          en: "WISSEL INTERN NEWBUY",
          nl: "WISSEL INTERN NEWBUY",
        },
      },
      WISSEL_INTERN_REPAIR: {
        text: {
          de: "WISSEL INTERN REPAIR",
          en: "WISSEL INTERN REPAIR",
          nl: "WISSEL INTERN REPAIR",
        },
      },
      WISSEL_EXTERN_NEWBUY: {
        text: {
          de: "WISSEL EXTERN NEWBUY",
          en: "WISSEL EXTERN NEWBUY",
          nl: "WISSEL EXTERN NEWBUY",
        },
      },
      WISSEL_EXTERN_REPAIR: {
        text: {
          de: "WISSEL EXTERN REPAIR",
          en: "WISSEL EXTERN REPAIR",
          nl: "WISSEL EXTERN REPAIR",
        },
      },
      FAC: { text: { de: "FAC ", en: "FAC ", nl: "FAC" } },
    },
    search: false,
  },
  projectStatus: {
    type: FieldTypes.checkbox,
    text: {
      de: "Inaktive Einheit",
      en: "Inactive Item",
      nl: "Inactief Item",
    },
    checkboxLabels: checkBoxOptions,
  },
  classNumber: {
    type: FieldTypes.value,
    text: {
      de: "Keine relevante LIMA-Klassifizierung",
      en: "No relevant LIMA classification",
      nl: "Geen relevante LIMA-classificatie",
    },
    search: false,
  },
  usedInSystem: {
    type: FieldTypes.value,
    text: {
      de: "Verwendet in",
      en: "Used in System",
      nl: "Gebruikt in Systeem",
    },
    search: false,
  },
  norm: {
    type: FieldTypes.value,
    text: {
      de: "Norm",
      en: "Norm",
      nl: "Norm",
    },
    search: false,
  },
  readilyAvailableStock: {
    type: FieldTypes.number,
    text: {
      de: "Verfügbarer Lagerbestand",
      en: "Readily available Stock",
      nl: "Voorraad schoon",
    },
    search: false,
  },
  safetyStock: {
    type: FieldTypes.number,
    text: {
      de: "Sicherheitsbestand",
      en: "Safety Stock",
      nl: "MIN safety stock",
    },
    search: false,
  },
  totalLifeCycleUsage: {
    type: FieldTypes.number,
    text: {
      de: "Bedarfsvorhersage bis zum Lebenszyklusende",
      en: "Life Cycle Demand Forecast",
      nl: "Prognose EOL",
    },
    search: false,
  },
  totalForecast2Years: {
    type: FieldTypes.number,
    text: {
      de: "24 Monate - Bedarfsvorhersage",
      en: "24 months demand Forecast",
      nl: "Prognose - 24 maanden",
    },
    search: false,
  },
  forecastEOLStock: {
    type: FieldTypes.number,
    text: {
      de: "Vorhersage des Bestands am Lebenszyklusende",
      en: "Forecast Stock Level at EOL",
      nl: "Voorraadstand EOL",
    },
    search: false,
  },
  prognosis: {
    type: FieldTypes.text,
    text: {
      de: "Prognose im Zeitverlauf",
      en: "Demand in Time",
      nl: "Behoefte in aantallen met periode",
    },
    maxLength: 128,
    search: false,
  },
  threadEditFields: {
    type: FieldTypes.value,
    text: {
      de: "Thread Fields eddited",
      en: "Thread Fields eddited",
      nl: "Thread Fields eddited",
    },
    search: false,
    multiple: true,
  },
  bpNumber: {
    type: FieldTypes.value,
    text: {
      de: "BP Nummer",
      en: "BP Number",
      nl: "BP Nummer",
    },
  },
  applicationArea: {
    type: FieldTypes.options,
    text: {
      de: "Bekannter Einsatzbereich",
      en: "Known Application Area",
      nl: "Bekend toepassingsgebied",
    },
    multiple: true,
  },
  unavailabilityRisk: {
    type: FieldTypes.options,
    text: {
      de: "Risiko der Nichtverfügbarkeit (System)",
      en: "Asset unavailability risk",
    },
    options: {
      low: { text: { de: "Low", en: "Low", nl: "Laag" } },
      high: { text: { de: "High", en: "High", nl: "Hoog" } },
    },
  },
  procLeadTime: {
    type: FieldTypes.number,
    text: {
      de: "Beschaffungsvorlaufzeit",
      en: "Procurement Lead Time weeks",
    },
    unit: {
      de: "in Wochen",
      en: "in weeks",
    },
  },
  procDate: {
    type: FieldTypes.date,
    text: {
      de: "Beschaffungsdatum (Datum oder auf Anfrage)",
      en: "Procurement date (date or on demand)",
    },
  },
  procQty: {
    type: FieldTypes.number,
    text: {
      de: "Geplante Beschaffungsmenge",
      en: "Procurement qty",
    },
  },
  stockInclProc: {
    type: FieldTypes.number,
    text: {
      de: "Aktueller Bestand, inkl. Beschaffungsmenge",
      en: "Current stock, incl proc",
    },
    mutable: false,
  },
  fdd: {
    type: FieldTypes.date,
    text: {
      de: "Voraussichtliches Lieferdatum (FDD)",
      en: "Forecast delivery date (FDD)",
    },
  },
  procComment: {
    type: FieldTypes.text,
    text: {
      de: "Kommentar zur Beschaffung",
      en: "Comments",
    },
    maxLength: 4096,
  },
  pricePerUnit: {
    type: FieldTypes.number,
    text: {
      de: "Preis pro Einheit",
      en: "Price per unit (in Euro)",
    },
    unit: {
      de: "in Euro",
      en: "in Euro",
    },
  },
  totalPrice: {
    type: FieldTypes.number,
    text: {
      de: "Gesamtpreis (in Euro)",
      en: "Total Price (in Euro)",
    },
    mutable: false,
    unit: {
      de: "in Euro",
      en: "in Euro",
    },
  },
  closedBy: {
    type: FieldTypes.value,
    text: {
      de: "Geschlossen von",
      en: "Closed by",
      nl: "Gesloten door",
    },
  },
  closedOn: {
    type: FieldTypes.timestamp,
    text: {
      de: "Geschlossen am",
      en: "Closed on",
      nl: "Gesloten op",
    },
  },
  pcnOrigin: {
    type: FieldTypes.options,
    text: {
      de: "PCN Herkunft",
      en: "PCN Origin",
    },
    search: false,
    options: {
      manufacturer: { text: { de: "Hersteller", en: "Manufacturer" } },
      distributor: {
        text: {
          de: "Distributor oder Bestücker",
          en: "Distributor or assembler",
        },
      },
      se: { text: { de: "SiliconExpert /RM", en: "SiliconExpert /RM" } },
    },
  },
  process: {
    type: FieldTypes.value,
    text: {
      de: "Siehe auch Vorgang",
      en: "See also process",
    },
    maxLength: 32,
    search: false,
  },
  datasheet: {
    type: FieldTypes.options,
    text: {
      de: "Datenblatt",
      en: "Data sheet",
    },
    options: {
      notChanged: { text: { de: "Nicht geändert", en: "Not changed" } },
      pcnNotAdded: {
        text: {
          de: "PCN/PDN-Hinweis hinzugefügt",
          en: "PCN/PDN Note added",
        },
      },
      updated: { text: { de: "Aktualisiert", en: "Updated" } },
      alreadyUpdated: {
        text: {
          de: "Wurde bereits aktualisiert",
          en: "Already updated",
        },
      },
    },
    search: false,
  },
  segment: {
    type: FieldTypes.value,
    text: {
      de: "Segment",
      en: "Segment",
      nl: "Segment",
    },
  },
  segSupplier: {
    type: FieldTypes.value,
    text: {
      de: "Segment Lieferant",
      en: "Segment Supplier",
      nl: "Segment Supplier",
    },
  },
  segObsNumber: {
    type: FieldTypes.value,
    text: {
      de: "Segment Obs. Alarm Nummer",
      en: "Segment Obs. Alert Number",
      nl: "Segment Obs. Alert Number",
    },
  },
  commentEOS: {
    type: FieldTypes.value,
    text: {
      de: "Kommentar zum EOS",
      en: "Comment on EOS",
      nl: "Comment on EOS",
    },
  },
  commentEOP: {
    type: FieldTypes.value,
    text: {
      de: "Kommentar zum EOP/Effective Date",
      en: "Comment on EOP/Effective Date",
      nl: "Comment on EOP/Effective Date",
    },
  },
  commentEOSR: {
    type: FieldTypes.value,
    text: {
      de: "Kommentar zum EOSR",
      en: "Comment on EOSR",
      nl: "Comment on EOSR",
    },
  },
  rmNotes: {
    type: FieldTypes.options,
    text: {
      de: "Notizen vorhanden?",
      en: "Notes available?",
    },
    mutable: false,
    options: {
      yes: { text: { de: "Ja", en: "Yes" } },
      no: { text: { de: "Nein", en: "No" } },
    },
  },
  criticalComponent: {
    type: FieldTypes.checkbox,
    text: {
      de: "Kritische Komponente",
      en: "Critical component",
    },
    checkboxLabels: checkBoxOptions,
  },
  otherCustomers: {
    type: FieldTypes.value,
    text: {
      de: "Weitere Kunden",
      en: "Other customers",
    },
  },
  pcnSource: {
    type: FieldTypes.options,
    text: {
      de: "PCN Quelle",
      en: "PCN Source",
    },
    options: {
      email: { text: { de: "E-mail", en: "E-mail" } },
      pcngen: { text: { de: "PCNGenerator", en: "PCNGenerator" } },
      se: { text: { de: "IHS/SE", en: "IHS/SE" } },
      inventory: {
        text: { de: "Bestandsüberwachung", en: "Inventory monitoring" },
      },
      other: { text: { de: "Sonstige", en: "Other" } },
    },
  },
  sapStatus: {
    type: FieldTypes.value,
    text: {
      de: "Material status (SAP)",
      en: "Materialstatus (SAP)",
    },
  },
  electronicalMat: {
    type: FieldTypes.checkbox,
    text: {
      de: "Elektronik-Mat (SAP)",
      en: "Electronical-Mat (SAP)",
    },
    checkboxLabels: checkBoxOptions,
  },
  valid: {
    type: FieldTypes.checkbox,
    text: {
      de: "Gültig",
      en: "Valid",
      nl: "Geldig",
    },
    checkboxLabels: checkBoxOptions,
  },
  pcnLink: {
    type: FieldTypes.value,
    text: {
      de: "Link zur PCN/PDN",
      en: "Link to PCN/PDN",
      nl: "Link to PCN/PDN",
    },
  },
  externalImageSource: {
    type: FieldTypes.value,
    text: {
      de: "Externes Bild Url",
      en: "External Image Url",
      nl: "Externe afbeelding Url",
    },
  },
  typeOfPost: {
    type: FieldTypes.radio,
    text: {
      de: "Art der Anzeige",
      en: "Type of posting",
    },
    options: {
      offer: { text: { de: "Biete", en: "Offer" } },
      seek: { text: { de: "Suche", en: "Seek" } },
    },
  },
  usage: {
    type: FieldTypes.options,
    text: {
      de: "Verwendung",
      en: "Usage",
    },
    options: {
      military: {
        text: {
          de: "Militärisch",
          en: "Military",
          nl: "Military",
        },
      },
      civil: {
        text: {
          de: "Zivil",
          en: "Civil",
          nl: "Civil",
        },
      },
    },
    multiple: true,
    sort: true,
  },
  criticality: {
    type: FieldTypes.options,
    text: {
      de: "Kritikalität",
      en: "Criticality",
      nl: "Kriticiteit",
    },
    options: {
      "very high": {
        text: {
          de: "Very High",
          en: "Very High",
          nl: "Very High",
        },
        color: "#EB5757",
      },
      high: {
        text: {
          de: "High",
          en: "High",
          nl: "High",
        },
        color: "#EB5757",
      },
      medium: {
        text: {
          de: "Medium",
          en: "Medium",
          nl: "Medium",
        },
        color: "#F2C94C",
      },
      low: {
        text: {
          de: "Low",
          en: "Low",
          nl: "Low",
        },
        color: "#27AE60",
      },
    },
  },
  consumption: {
    type: FieldTypes.value,
    text: {
      de: "Verbrauch letzte 24 Monate",
      en: "Last 24 months consumption",
    },
  },
  rangeForecast: {
    type: FieldTypes.value,
    text: {
      de: "Reichweiten Prognose in Monaten",
      en: "Range forecast in months",
    },
  },
  TISdescr1: {
    type: FieldTypes.value,
    text: {
      de: "TIS-Artikelstammdaten (Benennung1)",
      en: "TIS-Article-Master-Data (Description1)",
      nl: "TIS-Article-Master-Data (Description1)",
    },
  },
  TISdescr2: {
    type: FieldTypes.value,
    text: {
      de: "TIS-Artikelstammdaten (Benennung2)",
      en: "TIS-Article-Master-Data (Description2)",
      nl: "TIS-Article-Master-Data (Description2)",
    },
  },
  cageCode: {
    type: FieldTypes.typeahead,
    text: {
      de: "CAGE Code",
      en: "CAGE Code",
      nl: "CAGE Code",
    },
    mutable: true,
  },
  pcnStatus: {
    type: FieldTypes.options,
    text: {
      de: "Abkündigungsvermerk Artikelstamm",
      en: "PCN Status Article Master",
    },
    options: {
      notChanged: {
        text: {
          de: "Nicht geändert",
          en: "Not changed",
          nl: "Niet gewijzigd",
        },
      },
      noteAdded: {
        text: {
          de: "Vermerk hinzugefügt",
          en: "Note added",
          nl: "Opmerking toegevoegd",
        },
      },
      noteUpdated: {
        text: {
          de: "Vermerk aktualisiert",
          en: "Note updated",
          nl: "Opmerking bijgewerkt",
        },
      },
      noteWithdrawn: {
        text: {
          de: "Vermerk zurückgenommen",
          en: "Note withdrawn",
          nl: "Opmerking ingetrokken",
        },
      },
    },
  },
  instructions: {
    type: FieldTypes.value,
    text: {
      de: "Besondere Anweisungen",
      en: "Special instructions",
      nl: "Speciale instructies",
    },
  },
};

export const applicationAreaPredefinedOptions = [
  "U-Bahn, Strassenbahn, Stadtbahn",
  "S-Bahn (Vollbahn)",
  "Personenwagen, Reisezugwagen",
  "Güterwagen",
  "Rangierlok (Diesel / Elektro / Alternativ)",
  "Triebzug (Diesel / Elektro / Alternativ)",
  "Streckenlok (Diesel / Elektro / Alternativ)",
  "Steuerwagen",
  "Zahnradbahn",
  "Standseilbahn",
  "Spezialfahrzeug (Traktor, Kuppelwagen, Hilfswagen, Rettungszug)",
  "Infrastruktur (Stellwerke, Fahrbahn, Prüf- und Dienstgeräte, etc.)",
  "Kraftfahrzeuge (Bus, LKW, Bagger, etc.)",
];

const settings = {
  permission: {
    edit: false,
    editOwn: false,
    changeStatus: false,
    chooseCaseNumber: false,
    chooseSourceCase: false,
    hasPermissionToCreateThread: false,
    hasPermissionToSeeSeSearch: true,
  },
  text: {
    thread: { de: "Fall", en: "Case", nl: "Issue" },
    threads: { de: "Fälle", en: "Cases", nl: "Issues" },
    om: { de: "OM Module", en: "OM Module", nl: "OM Module" },
    create: {
      de: "Neuen Fall anlegen",
      en: "Create a new case",
      nl: "Nieuw Issue aanmelden",
    },
    caseNumberExist: {
      de: "Fall-Nummer schon vergeben!",
      en: "Case number already exist!",
    },
    pcnLinkFormat: {
      de: "Das PCN-Link-Format muss HTTP oder HTTPS enthalten!",
      en: "PCN Link format must include HTTP or HTTPS!",
    },
    invalidCaseNumberFormat: {
      de: "Ungültiges Format der Fall-Nummer!",
      en: "Invalid case number format!",
    },
    component: {
      de: "Betroffene Einheit",
      en: "Item Concerned",
      nl: "Betrokken Item",
    },
    calendar: {
      de: "Zeitliche Informationen",
      en: "Time Information",
      nl: "Tijd informatie",
    },
    showMore: {
      de: "Zeig mehr",
      en: "Show more",
      nl: "Laat meer zien",
    },
    showLess: {
      de: "Zeige weniger",
      en: "Show less",
      nl: "Laat minder zien",
    },
    comment: { de: "Kommentar", en: "Comment", nl: "Commentaar" },
    comments: { de: "Kommentare", en: "Comments", nl: "Commentaar" },
    solution: { de: "Lösung", en: "Solution", nl: "Afhandelstrategie" },
    solutions: { de: "Lösungen", en: "Solutions", nl: "Afhandelstrategieën" },
    editCase: { de: "Fall bearbeiten", en: "Edit case", nl: "Bewerk Issue" },
    task: { de: "Aufgabe", en: "Task", nl: "Taak" },
    tasks: { de: "Tasks", en: "Tasks", nl: "Tasks" },
    zip: {
      de: "Als .zip exportieren",
      en: "Export as .zip",
      nl: "Exporteren als .zip",
    },
    pdf: {
      de: "Als PDF exportieren",
      en: "Export as PDF",
      nl: "Exporteren als PDF",
    },
    exportAs: {
      de: "Fall exportieren als",
      en: "Export case as",
      nl: "Exporteer issue als",
    },
    uploadImage: {
      de: "Bild hochladen",
      en: "Upload Image",
      nl: "Afbeelding uploaden",
    },
    save: {
      de: "Änderungen übernehmen",
      en: "Save Changes",
      nl: "Bewaar de Wijzigingen",
    },
    discard: {
      de: "Änderungen verwerfen",
      en: "Discard Changes",
      nl: "Negeer de Wijzigingen",
    },
    deleteLabel: {
      de: "Fall löschen",
      en: "Delete Case",
      nl: "Issue verwijderen",
    },
    close: {
      de: "Detailansicht schließen",
      en: "Close Detail View",
      nl: "Sluit Detail Overzicht",
    },
    fav: {
      de: "Fall zur Favoritenliste hinzufügen",
      en: "Add Case to Favorites",
      nl: "Voeg Issue toe aan Favorieten",
    },
    unfav: {
      de: "Fall aus Favoritenliste entfernen",
      en: "Remove Case from favorites",
      nl: "Verwijder Issue uit Favorieten",
    },
    addToFav: {
      de: "Zu Favoriten hinzufügen",
      en: "Add to favorites",
      nl: "Toevoegen aan favorieten",
    },
    removeFav: {
      de: "Von Favoriten entfernen",
      en: "Remove from favorites",
      nl: "Verwijderen uit favorieten",
    },
    hoverNotificationSection: {
      de: "Pflichtfelder, um einen internen Fall zu erstellen",
      en: "Mandatory fields for Creating an Internal Case",
      nl: "Verplichte velden voor het aanmaken van een interne Issue",
    },

    //notification and contact data used only in ns client
    notification: {
      de: "OM - Meldung",
      en: "Open OM - Case",
      nl: "OM - Melding",
    },
    contactData: {
      de: "Kontaktdaten",
      en: "Contact Data",
      nl: "Contact Data",
    },
    main: { de: "Stammdaten", en: "Master Data", nl: "Stamdata" },
    impact: {
      de: "Betroffene Einheit",
      en: "Item Concerned",
      nl: "Betrokken Item",
    },
    layer: { de: "Interne Daten", en: "Internal Data", nl: "Interne Data" },
    history: { de: "Historie", en: "History", nl: "Historie" },
    impacts: { de: "Wo verwendet", en: "Where-Used", nl: "Waar gebruikt" },
    trainseries: { de: "Zugserie", en: "Train series", nl: "Materieelserie" },
    registration: { de: "Registration", en: "Registration", nl: "Registratie" },
    procurement: {
      de: "Beschaffungsinformationen",
      en: "Procurement Information",
    },
    files: { de: "Anhänge", en: "Attachments", nl: "Bijlagen" },
    deleteQuestion: {
      de: "Möchten Sie diesen Fall löschen?",
      en: "Would you like to delete this Case?",
      nl: "Wilt u dit Issue verwijderen?",
    },
    deleteImage: {
      de: "Möchten Sie dieses Bild wirklich entfernen?",
      en: "Would you like to delete this Image?",
      nl: "Wilt u deze Afbeelding verwijderen?",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    unFavCaseMessage: {
      de: "Fall aus Favoritenliste entfernen",
      en: "Remove Case from Favourites?",
      nl: "Verwijder Issue uit Favorieten?",
    },
    priority: {
      de: "Priorisierung und Steuerungsdaten",
      en: "Priority and Control",
      nl: "Prioritering en Controle",
    },
    obsolescence: {
      de: "Obsoleszenz Meldedaten",
      en: "Obsolescence",
      nl: "Obsolescence",
    },
    material: {
      de: "Materialdaten",
      en: "Material",
      nl: "Materiaal",
    },
    involved: {
      de: "Prozessbeteiligte (Personaldaten)",
      en: "Involved personnel",
      nl: "Betrokken personeel",
    },
    delete: {
      de: "Löschen",
      en: "Delete",
      nl: "Verwijder",
    },
    idRequired: {
      de: "ID fehlt",
      en: "ID required",
      nl: "ID nummer verplicht",
    },
    internalItems: { de: "SAP Daten", en: "SAP Data", nl: "SAP data" },
    situation: { de: "Ausgangslage", en: "Situation", nl: "Beginpunt" },
    deadlines: { de: "Termine", en: "Deadlines", nl: "Termijnen" },
    effort: { de: "Aufwandsbewertung", en: "Effort", nl: "Inspanning" },
    rest: {
      de: "Weitere Daten gemäß VDMA 24903",
      en: "VDMA 24903",
      nl: "VDMA 24903 norm",
    },
    prioritySection: {
      de: "Priorisierung und Steuerungsdaten",
      en: "Priority and Control",
      nl: "Prioritering en Controle",
    },
    obsolescenceSection: {
      de: "Obsoleszenz Meldedaten",
      en: "Obsolescence",
      nl: "Obsolescence",
    },
    materialSection: {
      de: "Materialdaten",
      en: "Material",
      nl: "Materiaal",
    },
    processSection: {
      de: "Prozessbeteiligte (Personaldaten)",
      en: "Involved Personnel",
      nl: "Betrokken Personeel",
    },
    messageInvalidForm: {
      de: "Die folgenden Felder sind ungültig:",
      en: "The following fields are invalid:",
      nl: "De volgende velden zijn ongeldig:",
    },
    uploadImageTypeError: {
      de: "Ungültige Datei! Datei muss ein Bild sein",
      en: "Invalid file! File must be an image",
      nl: "Invalid file! File must be an image",
    },
    deleteAllThreads: {
      de: "Alle Fälle löschen",
      en: "Delete all cases",
      nl: "Verwijder alle gevallen",
    },
    deleteCase: { de: "Löschen", en: "Delete Case", nl: "Issue verwijderen" },
    duplicateCase: {
      de: "Duplizierter Fall",
      en: "Duplicate Case",
      nl: "Dubbele zaak ",
    },
    searchInComponent: {
      de: "Suche in Komponentendatenbank",
      en: "Search from Component Database",
      nl: "Zoeken in de database met componenten",
    },
    casesCreatedByCPN: {
      de: "Bestehende Fälle für diese KTN:",
      en: "Existing cases for this CPN:",
      nl: "Bestaande gevallen voor dit CPN:",
    },
    duplicateCrtNumber: {
      de: "Bestehende Fälle für diese HTN:",
      en: "Existing cases for this MPN:",
      nl: "Bestaande gevallen voor dit MPN:",
    },
    caseImage: {
      de: "Fall-Bild",
      en: "Case Image",
      nl: "Issue Beeld",
    },
    duplicatePcnId: {
      de: "Bestehende Fälle für diese PCN ID: ",
      en: "Existing cases for this PCN ID: ",
      nl: "Bestaande gevallen voor dit PCN ID: ",
    },
    caseDetails: {
      de: "Fall-Details",
      en: "Case Details",
      nl: "Issue Details",
    },
    description: {
      de: "Beschreibung",
      en: "Description",
      nl: "Omschrijving",
    },
    caseNumber: {
      de: "Fall-Nummer:",
      en: "Case Number:",
      nl: "Issue Nummer:",
    },
    newCase: {
      de: "Neuer Fall",
      en: "New case",
      nl: "Nieuw Issue",
    },
    cpn: {
      de: "KTN:",
      en: "CPN:",
      nl: "CPN:",
    },
    mpn: {
      de: "HTN:",
      en: "MPN:",
      nl: "MPN:",
    },
    mfr: {
      de: "Hrst.Name:",
      en: "Mfr.Name:",
      nl: "Fab.Naam:",
    },
    mainCreateCase: { de: "Stammdaten", en: "Master Data", nl: "Stamdata" },
    caseLink: {
      de: "Fall-Link",
      en: "Case link",
    },
    duplicateTitle: {
      de: "Titel bereits vorhanden",
      en: "Title already exists",
      nl: "Title already exists",
    },
  },
  field,
  url: {},
  list: {
    description: ["thread.descr"],
    columns: empty,
    csvExportColumns: empty,
    exportILS003: empty,
    exportILS017: empty,
    exportILS018: empty,
    exportILS019: empty,
    boxesLeft: empty,
    boxesLeftEditMode: empty,
    boxesLeftCreateCase: empty,
    boxesLeftFromTree: empty,
    boxesRight: empty,
    boxesBottom: empty,
    boxesOnListOfOpenedCases: empty,
    filterBoxesHome: empty,
    fieldsOnFilter: empty,
    sortOptions: empty,
    notification: empty,
    contactData: empty,
    exportTypes: ["All Cases"],
    main: empty,
    impact: empty,
    comments: empty,
    calendar: empty,
    layer: empty,
    priority: empty,
    safetyEvaluation: empty,
    obsolescence: empty,
    material: empty,
    involved: empty,
    files: empty,
    impacts: empty,
    pdfExportItems: ["layer", "impact", "posts"],
    subTitleSection: empty,
    history: empty,
    internalItems: empty,
    situation: empty,
    deadlines: empty,
    effort: empty,
    rest: empty,
    layerSections: empty,
    prioritySection: [],
    obsolescenceSection: [],
    materialSection: [],
    processSection: [],
    trainseries: empty,
    registration: [],
    procurement: empty,
    quickFilters: [],
    mainCreateCase: empty,
  },
};

export const thread: ComponentSettings<typeof settings> = settings;
