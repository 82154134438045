import { Inject, Injectable } from "@angular/core";
import { AppType, APP_TYPE } from "../../app.type";
import { OCCommentServiceType } from "./OC-comment.service.type";
import { OCComment } from "../../../../../shared/models/obsolescence-community/OC-Comment";
import { ocCommentsByPostId, saveOCComment } from "../../../app/api.service";
import { hasChanges } from "../../utils/app.utils";

@Injectable()
export class OCCommentService implements OCCommentServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  commentDoc: OCComment = new OCComment();
  selectedComment: OCComment = new OCComment();
  cleanComment: OCComment = new OCComment();

  comments: OCComment[] = [];

  async saveComment() {
    this.app.spinner.showSpinner();
    await saveOCComment(
      this.app.customers.expectCurrent,
      this.commentDoc,
      this.app.OCPost.selectedPost._id
    );

    // Reset Model
    this.commentDoc = new OCComment();
    this.ocCommentsByPostId();
    this.app.OCHome.updatePostsSubject.next(true);
    this.app.OCPost.isReadMode = true;
    this.app.spinner.hideSpinner();
  }

  async updateComment() {
    this.app.spinner.showSpinner();
    await saveOCComment(
      this.app.customers.expectCurrent,
      this.cleanComment,
      this.app.OCPost.selectedPost._id
    );

    this.ocCommentsByPostId();
    this.app.OCHome.updatePostsSubject.next(true);
    this.app.OCPost.isReadMode = true;
    this.app.spinner.hideSpinner();
  }

  async deleteComment(selectedComment: any) {
    this.app.spinner.showSpinner();
    // Delete selected comment
    selectedComment._deleted = true;
    await saveOCComment(
      this.app.customers.expectCurrent,
      selectedComment,
      this.app.OCPost.selectedPost._id
    );
    this.ocCommentsByPostId();
    this.app.OCHome.updatePostsSubject.next(true);
    this.app.spinner.hideSpinner();
  }

  async ocCommentsByPostId() {
    if (this.app.OCPost.selectedPost != null) {
      this.comments = await ocCommentsByPostId(
        this.app.customers.expectCurrent,
        this.app.OCPost.selectedPost._id
      );
    }
    this.app.table.sort(this.comments, "ocComment");

    return this.comments;
  }

  modelHasChanges() {
    const modelHasChanges = hasChanges(this.cleanComment, this.selectedComment);
    if (this.cleanComment["message"] != "" && modelHasChanges) {
      return false;
    } else {
      return true;
    }
    // return !hasChanges(this.cleanComment, this.selectedComment);
  }

  discardChanges() {
    this.app.OCPost.isReadMode = true;
    this.app.OCHome.editedId = "";
  }
}
