import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { getApp } from "../../app";
import { Subscription } from "rxjs";
import { StringUtils } from "../../../../../shared/utils/string.utils";
import { FilterMode } from "./OC-home.service.type";

@Component({
  selector: "app-oc-home",
  templateUrl: "./OC-home.component.html",
  styleUrls: ["./OC-home.component.scss"],
})
export class OCHomeComponent implements OnInit, OnDestroy {
  updatePostsSubscription: Subscription = new Subscription();

  filterModes: FilterMode[] = ["title", "message", "user_id"];
  selectedType: string = "title";
  searchWords: any = {};
  selectedValue: string = "";
  isFilterSelected: boolean = false;

  show: boolean = false;

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {
    this.app.routing.setTabTitle("Obsolescence Community");
    this.app.OCHome.tags = this.app.field.getOptions(
      this.app.fieldId.ocPost.tagCategory
    );

    // populate post model with all needed information
    await this.app.OCHome.ocPosts();
    await this.setLikesOnPostsList();
    await this.app.OCComment.ocCommentsByPostId();
    await this.app.OCPost.isPostLikedByLoggedInUser(
      this.app.OCPost.selectedPost._id
    );
    this.app.OCPost.getImageExternal();

    this.updatePostsSubscription = this.app.OCHome.updatePostsSubject.subscribe(
      async (value) => {
        if (value) {
          await this.app.OCHome.ocPosts();
          await this.setLikesOnPostsList();
          await this.app.OCPost.isPostLikedByLoggedInUser(
            this.app.OCPost.selectedPost._id
          );
        }
      }
    );

    this.app.attachments.OCAttachmentsSubject.next(true);
  }

  toggleNewPost() {
    this.show = !this.show;
  }

  async setLikesOnPostsList() {
    // make sure that the profile has the updated like array
    const profile = this.app.profile.selectedProfile;
    let likedPostsIds = profile.like;
    this.app.OCHome.posts.forEach((post: any) => {
      if (likedPostsIds != null) {
        let isLiked = likedPostsIds[this.app.customers.expectCurrent].includes(
          post._id
        );
        if (isLiked) {
          post.isLikedByLoggedInUser = true;
        }
      }
    });
  }

  setType(type: string) {
    this.selectedType = type;
  }

  getTextDisplay(value: string) {
    switch (value) {
      case "user_id":
        return this.app.text.ocPost.own;
      case "title":
        return this.app.text.ocPost.title;
      case "message":
        return this.app.text.ocPost.description;
    }
  }

  isSelected(filter: string) {
    this.selectedValue = this.searchWords[filter];
    if (!StringUtils.isNullOrEmpty(this.selectedValue)) {
      this.isFilterSelected = true;
    } else {
      this.isFilterSelected = false;
    }
    return this.isFilterSelected;
  }

  async clearFilter() {
    this.selectedValue = "";
    this.isFilterSelected = false;
    this.searchWords = {};
    await this.app.OCHome.ocPosts();
    this.app.OCPost.selectedPost = this.app.OCHome.posts[0];
    this.app.OCPost.updatePostSubject.next(true);
    this.app.OCHome.updatePostsSubject.next(true);
  }

  ngOnDestroy() {
    if (this.updatePostsSubscription) {
      this.updatePostsSubscription.unsubscribe();
    }
  }
}
