import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Customer } from "../../../../shared/types/customers";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });
  displayFeatures: boolean = false;
  showAddUserBtn: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute) {}

  public ngOnInit(): void {
    if (
      this.app.customers.expectCurrent === Customer.OMP &&
      this.app.view === "se-filter"
    ) {
      this.displayFeatures = true;
    }
    this.showAddUserButton();

    if (this.router.url.split("/").includes("mail")) {
      //get the pcnId from url if the user is navigating to the pcn page, in order to display the correct title
      this.app.pcns.pcnId =
        this.route.snapshot.data.pcnId != null
          ? this.route.snapshot.data.pcnId
          : "";
      this.app.header.getHeaderTitle(this.router.url.split("/")[3]);
    }
    this.app.header.getHeaderTitle();
  }

  changeHeaderColor() {
    let headerColor = "white-header";
    switch (this.app.view) {
      case "home":
      case "thread":
      case "oc-home":
        headerColor = "om-nav-background";
        break;
      case "RM":
      case "manufacturer":
      case "part":
      case "matching":
      case "RMSearch":
      case "operations":
        headerColor = "rm-nav-background";
        break;
      case "se-filter":
        headerColor = "se-filter-header";
        break;
    }
    return headerColor;
  }

  navigateNewThread() {
    this.app.pcn.pcn = null;
    this.app.pcn.id = null;
    sessionStorage.removeItem("fromTree");
    sessionStorage.removeItem("manufacturer");
    this.app.routing.navigateNewThread();
  }

  displayOmLogo() {
    if (
      this.app.customers.expectCurrent === Customer.OMP ||
      this.app.customers.expectCurrent === Customer.COOP
    ) {
      return false;
    }
    return true;
  }

  setWhiteHeader() {
    switch (this.app.view) {
      case "mail":
      case "debug":
      case "import":
      case "users":
      case "user":
      case "profile":
      case "se-filter":
      case "graphs":
      case "user-profile-list":
      case "tasks":
        return true;
      default:
        return false;
    }
  }

  showAddUserButton() {
    if (this.app.view === "users" && this.app.permission.user.manage) {
      this.showAddUserBtn = true;
    }
    if (
      this.app.view === "users" &&
      this.app.customers.expectCurrent === Customer.DB &&
      this.app.auth.isTeam
    ) {
      this.showAddUserBtn = true;
    }
  }
}
